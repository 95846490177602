const SignIn = () => {
    return(
        <div>
            <div style={{height: '10vh'}}></div>
            <h6 className="center-align">Choose a Method</h6>
      <div className='center-align' style={{width: '249px',border:'3px solid black',margin: '0 auto'}}>
        <button className='white' style={{margin: '17px 10px 10px 10px', width: '215px'}}>
            <a href='auth/google' className="white black-text" >
            <div className="valign-wrapper" style={{padding: '10px'}}>
                <img  style={{width: '1.5rem', height: '1.5rem' }} src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                <span style={{paddingLeft: '5px',fontFamily: "Roboto"}}>Sign In with Google</span>
            </div>
            </a>
        </button>
        {/* <button className='white' style={{margin: '10px 10px 17px 10px', width: '215px'}}>
            <a href='auth/facebook' className="white black-text" >
            <div className="valign-wrapper" style={{padding: '10px'}}>
                <img  style={{width: '1.5rem', height: '1.5rem' }} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/2021_Facebook_icon.svg" loading="lazy" alt="google logo" />
                <span style={{paddingLeft: '5px',fontFamily: "Roboto"}}>Sign In with Facebook</span>
            </div>
            </a>
        </button> */}
      </div>
      </div>
    )
}

export default SignIn


