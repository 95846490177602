import {ADD_TODO,GET_TODOS,REMOVE_TODO, CLEAR_TODOS, EDIT_TODO} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_TODO:
            return action.payload || false;
        case GET_TODOS:
            return action.payload || false;
        case REMOVE_TODO:
            return action.payload || false;
        case CLEAR_TODOS:
            return action.payload || false;
        case EDIT_TODO:
            return action.payload || false;
        default:
            return state;
    }
}