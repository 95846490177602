import {GET_SETTINGS, SET_SETTING} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case GET_SETTINGS:
            return action.payload || false;
        case SET_SETTING:
            return action.payload || false;
        default:
            return state;
    }
}