import React,{useEffect,useState} from 'react';
import { getNfldata, setLastTeam } from '../actions';
import {connect} from 'react-redux';
import { nflTeamNames, nflTeamNameMap, nflDivisionMap, nflDivisionList, nflConferenceList } from './sportsHelpers';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

const Sports = ({auth,sportsdata,getNfldata,setLastTeam}) => {
  const [team, setTeam] = useState('');
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([])
  const [rows3, setRows3] = useState([])
  const [division, setDivision] = useState('');
  const [conference, setConference] = useState('');
  const [divisiondata, setDivisiondata] = useState([])
  const [confdata, setConfdata] = useState([])
  const [teamconf, setTeamconf] = useState('')
  const [loading, setLoading] = useState(true)
  const [bgpic, setBgpic] = useState('')

  const handleChange = (event) => {
    setTeam(event.target.value);
    setDivisiondata([])
    setDivision('')
    setConfdata([])
    setConference('')
    setLoading(true)
    getNfldata({team: event.target.value})
    setLastTeam({team: event.target.value})
  };

  // useEffect(()=>{
  //   console.log(auth)
  //   getNfldata({team: "NOR"})
  //   setTeam('NOR')
  // },[])

  useEffect(()=> {
    if (auth) {
      if (auth.lastteam) {
        getNfldata({team: auth.lastteam})
        setTeam(auth.lastteam)
      }
    }
  },[auth])

  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].name === obj.name) {
            return true;
        }
    }

    return false;
}

  useEffect(()=>{
    let tmprows=[]
    let oppname = ""
    if (sportsdata) {
      if (sportsdata[0].abbr === team) {
        setTeamconf(sportsdata[0].conf)
        if (window.innerWidth > window.innerHeight) {
          setBgpic(sportsdata[0].photo.landscape)
        } else {
          setBgpic(sportsdata[0].photo.portrait)
        }
        for (let step = 0; step < sportsdata[0].schedule.length; step++) {
          if (sportsdata[0].schedule[step].indexOf('@') !== -1) {
            const tmp = sportsdata[0].schedule[step].split('@')
            oppname=`@${nflTeamNameMap[tmp[1]]}`
          } else if (sportsdata[0].schedule[step].indexOf('BYE') !== -1) {
            oppname="BYE"
          } else {
            oppname=`${nflTeamNameMap[sportsdata[0].schedule[step]]}`
          }
          let disresult = ""
          if (sportsdata[0].results[step]) {
            disresult = sportsdata[0].results[step]
          }
          let disscore = ""
          if (sportsdata[0].scores[step]) {
            disscore = sportsdata[0].scores[step]
          }
          if (oppname === 'BYE') {
            disresult=""
            disscore=""
          }
          tmprows.push(createData(step+1,oppname,`${disresult}  ${disscore}`)) 
        }
        setRows(tmprows)
        if (!containsObject({name: sportsdata[0].name}, divisiondata)) {
          setDivisiondata(divisiondata => [...divisiondata, {name: sportsdata[0].name, record: sportsdata[0].record, rank: sportsdata[0].divrank}]);
        }
        if (!containsObject({name: sportsdata[0].name}, confdata)) {
          setConfdata(confdata => [...confdata, {name: sportsdata[0].name, record: sportsdata[0].record, rank: sportsdata[0].confrank}]);
        }
        setDivision(nflDivisionMap[sportsdata[0].abbr])
      } else {
        if (sportsdata[0].div === division) {
            if (!containsObject({name: sportsdata[0].name}, divisiondata)) {
          setDivisiondata(divisiondata => [...divisiondata, {name: sportsdata[0].name, record: sportsdata[0].record, rank: sportsdata[0].divrank}]);
          }
        }
        if (sportsdata[0].conf === conference) {
          if (!containsObject({name: sportsdata[0].name}, confdata)) {
            setConfdata(confdata => [...confdata, {name: sportsdata[0].name, record: sportsdata[0].record, rank: sportsdata[0].confrank}]);
          }
        }
      }
      
    }
    
    
    // setDivision
  },[sportsdata])

  useEffect(()=>{
    if (divisiondata) {
      if (divisiondata.length === 4) {
        let tmp = divisiondata
        tmp.sort((a,b) => a.rank - b.rank);
        let tmprows = []
        for (let step = 0; step < divisiondata.length; step++) {
          tmprows.push(createData2(tmp[step].rank,tmp[step].name,tmp[step].record)) 
        }
        setRows2(tmprows)
        setConference(teamconf)
      }
    }
  },[divisiondata])

  useEffect(()=>{
    if (confdata) {
      if (confdata.length === 16) {
        let tmp = confdata
        tmp.sort((a,b) => a.rank - b.rank);
        let tmprows = []
        for (let step = 0; step < confdata.length; step++) {
          tmprows.push(createData2(tmp[step].rank,tmp[step].name,tmp[step].record)) 
        }
        setRows3(tmprows)
        setLoading(false)
      }
    }
  },[confdata])

  useEffect(()=>{
    const tmp = nflDivisionList[division]
    const getDivData = async (divteam) => {
      await getNfldata({team: divteam})
    }
    if (tmp) {
      for (let step = 0; step < tmp.length; step++) {
        getDivData(tmp[step])
      }
    }
  },[division])

  useEffect(()=>{
    const tmp = nflConferenceList[conference]
    const getDivData = async (divteam) => {
      await getNfldata({team: divteam})
    }
    if (tmp) {
      for (let step = 0; step < tmp.length; step++) {
        getDivData(tmp[step])
      }
    }
  },[conference])

  function createData(week, opponent, result) {
    return { week, opponent, result };
  }

  function createData2(rank, name, record) {
    return { rank, name, record };
  }

    return(
      <div style={{marginTop: '40px', marginBottom: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <div style={{width: window.innerWidth > 800 ? '50%' : '90%', margin: '0 auto', zIndex:1000, backgroundColor: 'white'}}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel shrink={false}>{team ? "" : "Team"}</InputLabel>
              <Select value={team} label="Team" onChange={handleChange} style={{padding: '0 !important'}} sx={{
                                          padding: "0 !important",
                                          boxShadow: "none",
                                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0 
                                            },
                                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: 0 
                                            },
                                        }}>
                {nflTeamNames.map((tm)=>{
                  return (
                    <MenuItem key={nflTeamNameMap[tm]} value={nflTeamNameMap[tm]}>{tm}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
        { !bgpic ? null : loading ? <div style={{ justifyContent: 'center',display: 'flex', background: 'rgba(0, 0, 0, 0.75)', zIndex: 900, margin: '40px auto', width: '200px'}}> <CircularProgress style={{margin: '50px auto'}} /> </div> :
        <div style={{width: window.innerWidth > 800 ? 'auto' : '98%', margin: '40px auto', padding: window.innerWidth > 800 ? '30px' : '10px', background: 'rgba(0, 0, 0, 0.5)', zIndex: 900, display: 'flex', flexDirection: window.innerWidth > 800 ? 'row': 'column'}}>
          
          <div>
            <h6 style={{color: 'white', textAlign: 'center'}}>Schedule</h6>
            <TableContainer component={Paper} style={{backgroundColor: 'transparent'}}>
              <Table sx={{ minWidth: 50 }} size="small" aria-label="simple table">
                <TableHead style={{backgroundColor: 'transparent'}}>
                  <TableRow style={{backgroundColor: 'transparent'}}>
                    <TableCell align="center" style={{color: 'white'}}>Week</TableCell>
                    <TableCell align="left" style={{color: 'white'}}>Opponent</TableCell>
                    <TableCell align="left" style={{color: 'white'}}>Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row,index) => {
                    return (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{backgroundColor: 'transparent'}}>
                      <TableCell align="center" style={{color: 'white'}}>{row.week}</TableCell>
                      <TableCell align="left" style={{color: 'white'}}>{row.opponent}</TableCell>
                      <TableCell align="left" style={{color: 'white'}}>{row.result}</TableCell>
                    </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{paddingLeft: window.innerWidth > 800 ? '100px' : '0px',paddingTop: window.innerWidth > 800 ? '0px' : '50px'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>
              <h6 style={{color: 'white', textAlign: 'center'}}>Division Standing</h6>
                <TableContainer component={Paper} style={{backgroundColor: 'transparent'}}>
                  <Table sx={{ minWidth: 50 }} size="small" aria-label="simple table">
                    <TableHead style={{backgroundColor: 'transparent'}}>
                      <TableRow style={{backgroundColor: 'transparent'}}>
                        <TableCell align="center" style={{color: 'white'}}>Rank</TableCell>
                        <TableCell align="left" style={{color: 'white'}}>Name</TableCell>
                        <TableCell align="center" style={{color: 'white'}}>Record</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows2.map((row,index) => {
                        return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{backgroundColor: 'transparent'}}>
                          <TableCell align="center" style={{color: 'white'}}>{row.rank}</TableCell>
                          <TableCell align="left" style={{color: 'white'}}>{row.name}</TableCell>
                          <TableCell align="center" style={{color: 'white'}}>{row.record}</TableCell>
                        </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div style={{marginTop: '50px'}}>
              <h6 style={{color: 'white', textAlign: 'center'}}>Playoff Standing</h6>
                <TableContainer component={Paper} style={{backgroundColor: 'transparent'}}>
                  <Table sx={{ minWidth: 50 }} size="small" aria-label="simple table">
                    <TableHead style={{backgroundColor: 'transparent'}}>
                      <TableRow style={{backgroundColor: 'transparent'}}>
                        <TableCell align="center" style={{color: 'white'}}>Rank</TableCell>
                        <TableCell align="left" style={{color: 'white'}}>Name</TableCell>
                        <TableCell align="center" style={{color: 'white'}}>Record</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows3.map((row,index) => {
                        return (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{backgroundColor: 'transparent'}}>
                          <TableCell align="center" style={{color: 'white'}}>{row.rank}</TableCell>
                          <TableCell align="left" style={{color: 'white'}}>{row.name}</TableCell>
                          <TableCell align="center" style={{color: 'white'}}>{row.record}</TableCell>
                        </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>}
        {/* <div style={{position: 'absolute',top: 0, left: 0, height: '100vh'}}>
          <img id='image' style={{height: '100%',width: '100%',objectFit: 'cover'}} src='https://cdn.vox-cdn.com/thumbor/YzsmXXP_b5x06kyDSPNKpGY4IKM=/0x0:4816x3211/1200x800/filters:focal(2023x1221:2793x1991)/cdn.vox-cdn.com/uploads/chorus_image/image/73558866/usa_today_21204246.0.jpg' />
        </div> */}
        {bgpic ? <div id="bg">
            {/* <img style={{objectFit: 'fill'}} src='https://cdn.vox-cdn.com/thumbor/YzsmXXP_b5x06kyDSPNKpGY4IKM=/0x0:4816x3211/1200x800/filters:focal(2023x1221:2793x1991)/cdn.vox-cdn.com/uploads/chorus_image/image/73558866/usa_today_21204246.0.jpg' /> */}
            <img style={{height: '100%',width: '100%',objectFit: 'cover'}} src={bgpic} />
        </div> : null}
      </div>
    )
}

function mapStateToProps(state) {
  return {
          sportsdata: state.sportsdata,
          auth: state.auth
         }
}

export default connect(mapStateToProps, {getNfldata,setLastTeam})(Sports);