import React, {useEffect,useState} from 'react';
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux';
import { addPhoto, getPhotos, removePhoto } from '../actions';
import CircularProgress from '@mui/material/CircularProgress';

const Photos = ({photos, getPhotos,addPhoto, removePhoto}) => {
    const [pic, setPic] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        getPhotos()
      //   return () => {
      //     document.getElementById('main').style.backgroundColor = "white";
      // }
      },[])

      useEffect(()=>{
        let i = 0
        var timer = setInterval(()=>{
          if (photos) {
            if (photos.length > 0) {
              setLoading(false)
              // document.getElementById('main').style.backgroundColor = "green";
              setPic(photos[i].url)
              // console.log(window.innerWidth)
              i = i + 1
              if ( i == photos.length) {
                i = 0
              }
            }
          }
        }, 1000*60*0.1 )
        return function cleanup() {
            clearInterval(timer)
        }
      },[photos])

    return(
      <div style={{ maxWidth: window.innerWidth,display: 'flex',overflow: 'hidden', marginTop: '40px', alignItems: 'center', justifyContent: 'center', height: '105vh'}}>
        {loading
        ?
        <div style={{ justifyContent: 'center',display: 'flex'}}> <CircularProgress style={{marginTop: '50px'}} /> </div> 
        :
        // <div style={{height: '80vh'}}>
        //   <img style={{height: '100%',width: '100%',objectFit: 'cover'}} src={pic} />
        // </div>
          <div style={{position: 'absolute',top: 0, width: '100vw'}}>
            <img id='image' style={{height: '100%',width: '100%',objectFit: 'cover'}} src={pic} />
          </div>
          // <div id="bg">
          //     <img src={pic}  />
          // </div>

        }
      </div>
    )
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
          photos: state.photos,
         }
}

export default withRouter(connect(mapStateToProps,{getPhotos,addPhoto, removePhoto})(Photos))