import React, {Component,useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Features = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
      <div style={{border: '2px solid black',overflow: 'hidden', height: window.innerWidth > 800 ? '700px' : '650px', width: window.innerWidth > 800 ? '85%' : '90%', margin: '0 auto'}}>
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
            <Tabs
                orientation="vertical"
                // variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider', width: '150px' }}
            >
                <Tab label="Family" style={{textTransform:'none'}} {...a11yProps(0)} />
                <Tab label="Calendar" style={{textTransform:'none'}} {...a11yProps(1)} />
                <Tab label="Tasks" style={{textTransform:'none'}} {...a11yProps(2)} />
                <Tab label="Lists" style={{textTransform:'none'}} {...a11yProps(3)} />
                <Tab label="Countdown" style={{textTransform:'none'}} {...a11yProps(4)} />
                <Tab label="Photos" style={{textTransform:'none'}} {...a11yProps(5)} />
                <Tab label="NEW! Sports" style={{textTransform:'none'}} {...a11yProps(6)} />
                <Tab label="NEW! Crypto" style={{textTransform:'none'}} {...a11yProps(7)} />
            </Tabs>
            <TabPanel value={value} index={0} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824865/MyDisplay/Screenshot_20241020_204757_Kiwi_Browser_wfbnk1.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Add your family members to help keep everyone organized!</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824865/MyDisplay/Screenshot_20241023_161644_Kiwi_Browser_b6yzea.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Add your family members to help keep everyone organized!</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={1} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824773/MyDisplay/Screenshot_20240807_072917_Chrome_vkfaah.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Use a shared calendar to keep track of important events</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824773/MyDisplay/Screenshot_20241023_180306_Chrome_tocsbp.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif', marginTop: '20px'}}>Use a shared calendar to keep track of important events</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={2} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729825230/MyDisplay/Screenshot_20240810_073522_Chrome_sesugl.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Assign tasks to everyone in your family and keep them organized</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729825229/MyDisplay/Screenshot_20240810_075921_Chrome_bdnu0o.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Assign tasks to everyone in your family and keep them organized</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={3} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824838/MyDisplay/Screenshot_20241020_205209_Kiwi_Browser_wyjrax.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Keep track of all your lists and todos</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824838/MyDisplay/Screenshot_20241023_161714_Kiwi_Browser_ndaj6i.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Keep track of all your lists and todos</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={4} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729825103/MyDisplay/Screenshot_20241020_205310_Kiwi_Browser_iwzgex.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Count down to important dates!</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729825103/MyDisplay/Screenshot_20241024_205746_Chrome_vin4rl.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Count down to important dates!</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={5}  style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1730038213/MyDisplay/Screenshot_20241020_210333_Kiwi_Browser_mbdeem.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Slideshow your favorite pictures</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1730038215/MyDisplay/Screenshot_20241023_162027_Kiwi_Browser_nws2qe.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>Slideshow your favorite pictures</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={6} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729825176/MyDisplay/Screenshot_20241020_205419_Kiwi_Browser_jc6n8r.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>New from user feedback! Keep track of your favorite NFL team</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729825176/MyDisplay/Screenshot_20241024_205903_Chrome_djq0j7.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>New from user feedback! Keep track of your favorite NFL team</h5>
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={7} style={{width: '100%', height: '100%'}}>
                { window.innerWidth > 800 ?
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824896/MyDisplay/Screenshot_20241020_205933_Kiwi_Browser_xsfpqj.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>New from user feedback! Keep track of your crypto portfolio without connecting your wallet!</h5>
                    </div>
                :
                    <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                        <img alt="feature description" style={{maxWidth: '100%', width: '100%', objectFit: 'cover'}} src='https://res.cloudinary.com/dk1bgtbr9/image/upload/v1729824896/MyDisplay/Screenshot_20241023_162405_Chrome_yi9nlt.jpg' />
                        <h5 style={{fontFamily: '"Slabo 27px", serif'}}>New from user feedback! Keep track of your crypto portfolio without connecting your wallet!</h5>
                    </div>
                }
            </TabPanel>
        </Box>
      </div>
    )
}

export default Features



