import React from 'react'
import { Link } from "react-router-dom";

const NotFound = () => {
    return(
      <div style={{marginTop: '100px', display: 'flex', flexDirection: 'row'}}>
        <p style={{fontSize: window.innerWidth > 800 ? 64 : 28, fontFamily: '"Slabo 27px", serif', textAlign: 'center', width: '100%'}}>Oops! We can't find the page you are looking for. Let's <Link to="/">head home</Link> and try again.</p>
      </div>
    )
}

export default NotFound