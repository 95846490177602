import {FETCH_USER} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case FETCH_USER:
            if (action.payload.hasOwnProperty('_id')) {
                return action.payload
            } else {
                return false
            }
            // return action.payload || false;
        default:
            return state;
    }
}