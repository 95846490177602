import React,{useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import { useState } from "react";
import FormControl, { useFormControl } from '@mui/material/FormControl';
import ProfileCard from "./ProfileCard";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HuePicker } from 'react-color';
import {connect} from 'react-redux';
import { addFamily,getFamily,removeFamily,editFamilyMember } from '../actions';
import { v4 as uuidv4 } from 'uuid';
import UploadWidget from './UploadWidget';
import CircularProgress from '@mui/material/CircularProgress';


const Family = ({auth,family,photo,addFamily,getFamily,removeFamily,editFamilyMember}) => {
    const [activeUser, setActiveUser] = useState('')
    const [openaddfam, setOpenaddfam] = useState(false)
    const [openedit, setOpenedit] = useState(false)
    const [familymembers, setFamilymembers] = useState([{name: "Add Family Member", initials: "+", color: "rgb(0,0,0)", id: '000', photo: ''}])
    const [users, setUsers] = useState([])
    const [sketchPickerColor, setSketchPickerColor] = useState({r: "241",g: "112",b: "19",a: "1"});
    const [photoURL, setPhotoURL] = useState('')
    const [editName, setEditName] = useState('')
    const [editColor, setEditColor] = useState('')
    const [editID, setEditID] = useState('')
    const [loading, setLoading] = useState(true)

    const { r, g, b, a } = sketchPickerColor;

    useEffect( () => {
        getFamily()
      },[])

      useEffect(()=>{
        console.log(auth)
        if (auth) {
          setLoading(false)
        } else if (auth === false) {
          setLoading(false)
        }
      },[auth])

      useEffect( () => {
        // console.log('** ',photoURL)
      },[photoURL])

      useEffect( () => {
        if (photo.url) {
          setPhotoURL(photo.url)
        }
      },[photo])

      useEffect( () => {
        if (family !== null){
            const tmp4 = family.concat([{name: "Add Family Member", initials: "+", color: "rgb(0,0,0)", photo: ''}])
            setFamilymembers(tmp4)
        }
      },[family])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth < 800 ? '95%' : 450,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      const addNewFamily = (initials) => {
        if (initials === '+') {
            setOpenaddfam(true)
        }
      }

      const editFamily = (event,obj) => {
        event.preventDefault()
        setEditName(obj.name)
        const [r,g,b,a] = obj.usercolor.match(/[\d\.]+/g).map(Number);
        setSketchPickerColor({r,g,b,a:'1'})
        setPhotoURL(obj.photo)
        setEditID(obj.famid)
        event.currentTarget.blur()
        setOpenedit(true)
      }

      const titleCase = (str) => {
        return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
            return match.toUpperCase();
        });
    }
    
    

      const addFam = async (event) => {
        event.preventDefault()
        setOpenaddfam(false)

        const x = document.querySelector('[name="famname"]').value

        var abbr = x.match(/\b(\w)/g).join('').toUpperCase();

        const fammem = {
            name: titleCase(x),
            initials: abbr,
            color: `rgb(${r},${g},${b})`,
            id: uuidv4(),
            photo: photoURL
        }

        await addFamily(fammem)
        await getFamily()
        setPhotoURL('')
      }

      const editFam = async (event) => {
        event.preventDefault()
        const x = document.querySelector('[name="editname"]').value
        var abbr = x.match(/\b(\w)/g).join('').toUpperCase();
        const fammem = {
          name: titleCase(x),
          initials: abbr,
          color: `rgb(${r},${g},${b})`,
          id: editID,
          photo: photoURL
      }
      await editFamilyMember(fammem)
      setOpenedit(false)
      setPhotoURL('')
      }

      const delFamily = async (event) => {
        await removeFamily(event)
        await getFamily()
      }

      const handleAddFamClose = () => setOpenaddfam(false);
      const handleEditClose = () => setOpenedit(false);

      const handleChangeComplete = (color) => {
        // console.log(color);
      };
      

    return(
      loading ? <div style={{ justifyContent: 'center',display: 'flex'}}> <CircularProgress style={{marginTop: '50px'}} /> </div>   :
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap',marginTop: '30px', justifyContent: 'center'}}>
            {familymembers.map((member)=>{
                return (
                    <ProfileCard key={`${member.id}`} onRemove={delFamily} onClick={addNewFamily} onEdit={editFamily} name={member.name} initials={member.initials} famid={member.id} usercolor={member.color} photo={member.photo}/>
                )
            })}
            </div>
            <Modal open={openaddfam} onClose={handleAddFamClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} >
                    <form noValidate autoComplete="off" onSubmit={(event) => addFam(event)} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <h5 style={{textAlign: 'center', fontSize: '18', marginTop: '0px'}}>Add a New Family Member!</h5>
                        <div style={{margin: '8px auto', display: 'flex', justifyContent: 'center'}}>
                            {photoURL && <img src={photoURL} style={{width: '50%'}} alt="location thumbnail" />}
                        </div>
                        <div style={{margin: '8px auto'}}>
                          <UploadWidget />
                        </div>
                        <FormControl fullWidth>
                            <TextField style={{marginBottom: '8px'}} name="famname" label="Name" variant="outlined" inputProps={{style: {paddingLeft: 5}}}/>
                            <HuePicker width='100%' disableAlpha={true} color={sketchPickerColor} onChange={(color) => {setSketchPickerColor(color.rgb)}} onChangeComplete={ handleChangeComplete }/>
                                <div style={{marginTop: '8px', width: "100%",height: "10px",backgroundColor: `rgba(${r},${g},${b},${a})`}}></div>
                        </FormControl>
                        <div style={{paddingTop: '15px'}}>
                            <Button variant='outlined' onClick={()=>setOpenaddfam(false)}>Cancel</Button>
                            <Button variant='contained' type="submit" style={{float: 'right'}}>Add</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
            <Modal open={openedit} onClose={handleEditClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} >
                    <form noValidate autoComplete="off" onSubmit={(event) => editFam(event)} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <h5 style={{textAlign: 'center', fontSize: '18', marginTop: '0px'}}>Edit Family Member</h5>
                        <div style={{margin: '8px auto', display: 'flex', justifyContent: 'center'}}>
                            {photoURL && <img src={photoURL} style={{width: '50%'}} alt="location thumbnail" />}
                        </div>
                        <div style={{margin: '8px auto'}}>
                          <UploadWidget />
                        </div>
                        <FormControl fullWidth>
                            <TextField defaultValue={editName} style={{marginBottom: '8px'}} name="editname" label="Name" variant="outlined" inputProps={{style: {paddingLeft: 5}}}/>
                            <HuePicker width='100%' disableAlpha={true} color={sketchPickerColor} onChange={(color) => {setSketchPickerColor(color.rgb)}} onChangeComplete={ handleChangeComplete }/>
                                <div style={{marginTop: '8px', width: "100%",height: "10px",backgroundColor: `rgba(${r},${g},${b},${a})`}}></div>
                        </FormControl>
                        <div style={{paddingTop: '15px'}}>
                            <Button variant='outlined' onClick={()=>setOpenedit(false)} >Cancel</Button>
                            <Button variant='contained' type="submit" style={{float: 'right'}}>Finished</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    )
}

function mapStateToProps(state) {
    return {
            family: state.family, 
            photo: state.photo,
            auth: state.auth
           }
  }
  
export default connect(mapStateToProps, {addFamily,getFamily,removeFamily,editFamilyMember})(Family);