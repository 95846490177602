import {ADD_PHOTO,GET_PHOTOS,REMOVE_PHOTO} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_PHOTO:
            return action.payload || false;
        case GET_PHOTOS:
            return action.payload || false;
        case REMOVE_PHOTO:
            return action.payload || false;
        default:
            return state;
    }
}