import { combineReducers } from "redux";
import authReducer from "./authReducer";
import familyReducer from "./familyReducer";
import eventsReducer from "./eventsReducer";
import todosReducer from "./todosReducer";
import listsReducer from "./listsReducer";
import countdownReducer from "./countdownReducer";
import guestsReducer from "./guestsReducer";
import positionsReducer from "./positionsReducer";
import settingsReducer from "./settingsReducer";
import photosReducer from "./photosReducer";
import photoReducer from "./photoReducer";
import sportsReducer from "./sportsReducer";

export default combineReducers({
    auth: authReducer,
    family: familyReducer,
    masterevents: eventsReducer,
    mastertodos: todosReducer,
    masterlists: listsReducer,
    mastercountdown: countdownReducer,
    masterguests: guestsReducer,
    positions: positionsReducer,
    settings: settingsReducer,
    photos: photosReducer,
    photo: photoReducer,
    sportsdata: sportsReducer,
})
