import {ADD_LIST_ITEM,GET_LISTS,REMOVE_LIST_ITEM, CLEAR_LIST_ITEMS, DELETE_LIST, CREATE_LIST, EDIT_LIST_ITEM, MOVE_LIST} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_LIST_ITEM:
            return action.payload || false;
        case GET_LISTS:
            return action.payload || false;
        case REMOVE_LIST_ITEM:
            return action.payload || false;
        case CLEAR_LIST_ITEMS:
            return action.payload || false;
        case DELETE_LIST:
            return action.payload || false;
        case CREATE_LIST:
            return action.payload || false;
        case MOVE_LIST:
            return action.payload || false;
        case EDIT_LIST_ITEM:
            return action.payload || false;
        default:
            return state;
    }
}