import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const Payment = ({auth}) => {
    const [loading, setLoading] = useState(true)
    const history = useHistory();

    useEffect(()=> {
        // console.log(auth)
        if (auth?.paid === true) {
            history.push("/family");
          } else if (auth?.paid === false) {
            setLoading(false)
          }
    },[auth])

    return(
        loading ? <div style={{ justifyContent: 'center',display: 'flex'}}> <CircularProgress style={{marginTop: '50px'}} /> </div>   :
        <div className="container">
            <div>
                <h1 className="center-align" style={{fontFamily: '"Slabo 27px", serif'}}>Unlock Your Display!</h1>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div className="card" style={{borderRadius : "15%", overflow:"hidden"}}>
                    
                    <div className="card-content">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px'}}>
                            <LockOpenIcon fontSize="large" style={{color: 'rgb(25,118,211)', transform: 'scale(2.8)',}}/>
                        </div>
                        <h4 className="center-align" style={{marginTop:'50px',fontFamily: '"Slabo 27px", serif'}}>$9.99</h4>
                        <br></br>
                        <p style={{fontFamily: '"Slabo 27px", serif'}}>Unlock access to all our features:</p>
                            <ul >
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>Calendar</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>Tasks (Great for daily chores)</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>Lists (Great for keeping up with groceries)</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>Countdown Views (How long until my birthday?!)</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>Photo Screensaver</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>NEW from customer feedback: Track your crypto portfolio</li>
                                <li style={{listStyleType: "initial",marginLeft:"30px",fontFamily: '"Slabo 27px", serif'}}>NEW from customer feedback: Keep up with your favorite NFL team's progress</li>
                            </ul>  
                        <p style={{fontFamily: '"Slabo 27px", serif'}}>After clicking 'Checkout,' you will be redirected to Stripe to process your payment. This allows us to focus on creating a better service for you, and allows Stripe to focus on protecting your payments.</p>
                    </div>
                    <div className="card-action center-align">
                        <form action="/create-checkout-session1" method="POST">
                            <button type="submit">Checkout</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}


function mapStateToProps(state) {
    return {
            auth: state.auth, 
           }
  }


export default withRouter(connect(mapStateToProps,{ })(Payment))