import {Link} from 'react-router-dom';

const Footer = () => {

    return(
        <footer className="" style={{alignSelf: "flex-end", paddingTop:"0px", paddingTop: '10px', paddingBottom: '10px', backgroundColor: 'blue'}}>
          <div className="" style={{paddingBottom: '10px', paddingRight: '10px'}}>
            <div className="" style={{width: "100%", padding: "0 5%", display: 'flex', flexDirection: 'row'}}>
              <div style={{display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column'}}>
                <Link to='/tou' className="white-text" style={{textDecorationLine: 'underline',margin: '0 10px'}}>Terms of Use</Link>
                <Link to='/privacy' className="white-text" style={{textDecorationLine: 'underline',margin: '0 10px'}}>Privacy Policy</Link>
                {window.innerWidth > 800 ?
                  <div>
                  <Link to='/cookies' className="white-text" style={{textDecorationLine: 'underline',margin: '0 10px'}}>Cookies Policy</Link>
                  <a href="mailto:support@mydisplay.io" className='white-text' style={{textDecorationLine: 'underline',margin: '0 10px'}}>Contact Us</a>
                  </div> : null}
              </div>
              {window.innerWidth <= 800 ? 
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Link to='/cookies' className="white-text" style={{textDecorationLine: 'underline',margin: '0 10px'}}>Cookies Policy</Link>
                  <a href="mailto:support@mydisplay.io" className='white-text' style={{textDecorationLine: 'underline',margin: '0 10px'}}>Contact Us</a>
                </div> : null}
              {window.innerWidth > 800 ? <label style={{marginLeft: 'auto'}} className="right white-text">© MyDisplay 2024</label> : null}
            </div>
            {window.innerWidth <= 800 ? <label style={{marginLeft: 'auto'}} className="right white-text">© MyDisplay 2024</label> : null}
          </div>
        </footer>
    )
}


export default Footer;