import React, {Component,useEffect,useState,useRef} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import { getPositions, removePosition, addPosition } from '../actions';
import useWebSocket,{ReadyState} from "react-use-websocket"
import _ from "lodash";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {Link} from 'react-router-dom';
import Table from './Table';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
 


const Crypto = ({positions, getPositions, removePosition, addPosition}) => {
  const [cbs, setCbs] = useState(['AMP']);
  const [prices, setPrices] = useState({});
  const [values, setValues] = useState([]);
  const [valuedates, setValuedates] = useState([]);
  const [charts,setCharts] = useState([]);
  const [tableConfig,setTableConfig] = useState([]);
  const [tableData,setTableData] = useState([]);
  const [width1, setWidth1] = useState('')
  const [width2, setWidth2] = useState('')
  const [width3, setWidth3] = useState('')
  const [width4, setWidth4] = useState('')
  const [width5, setWidth5] = useState('')
  const [width6, setWidth6] = useState('')
  const [waiting, setWaiting] = useState(true)

  const refElem1 = useRef();
  const refElem2 = useRef();
  const refElem3 = useRef();
  const refElem4 = useRef();
  const refElem5 = useRef();
  const refElem6 = useRef();

  useEffect(() => {
    getPositions()
  }, []);

  useEffect(() => {
    let tmp = []
    _.forEach(positions, position => {
      tmp.push(position.symbol) 
    })
    setCbs(tmp)
    if (positions != null) {
      if (positions.length > 0) {

      } else {
        setWaiting(false)
      }
    }
    
  }, [positions]);

  // positions arrya of objects that look like this:
  // {
  //   symbol: "AMP"
  //   position: "1000000"
  //   cost: "0"
  // }

  var { getWebSocket, readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(  "wss://ws-feed.exchange.coinbase.com" );
  const getWebsocketCB=getWebSocket
  const readyStateCB=readyState
  const sendJsonMessageCB=sendJsonMessage
  const lastJsonMessageCB=lastJsonMessage

  useEffect(() => {
    // check if websocket is open
    if(readyStateCB === ReadyState.OPEN){
        // console.log('test')
        // console.log(cbs)
        let assets = []
        if (cbs.length > 0){
            _.forEach(cbs, crypto => {
                assets.push(`${crypto}-USD`)
            })
        }
          sendJsonMessageCB({
            type: "subscribe",
            product_ids: assets,
            channels: ["ticker_batch"],
           })
         }
      }, [readyStateCB,cbs])

    useEffect(() => {
      if (lastJsonMessageCB) {
         const { channels, ...payload } = lastJsonMessageCB;
         const type = payload.product_id;
         const pair=type
         const price=payload.price
         setPrices({...prices,[pair]:price})
       }
    }, [lastJsonMessageCB]);

    const keyFn = (obj) => {
      return obj.symbol;
    }

    const iconDeleteClick = async (event,obj) => {
        // console.log(obj)
        await removePosition(obj)
        event.target.blur()
  }

      useEffect(()=> {
        function countProperties(obj) {
            var count = 0;
        
            for(var prop in obj) {
                if(obj.hasOwnProperty(prop))
                    ++count;
            }
        
            return count;
        }

        
        let tmp = countProperties(prices)

        // if (tmp !== cbs.length + 1) {
            // setCbs([...cbs])
        // } else 
        // {
            let tmpA = [
                {label: 'Symbol', render: (obj) => <div ref={refElem1}>{obj.symbol}</div>},
                {label: 'Cost', render: (obj) => <div ref={refElem2}>{obj.cost}</div>},
                {label: 'Position', render: (obj) => <div ref={refElem3}>{obj.position}</div>},
                {label: 'Value', render: (obj) => <div ref={refElem4}>{obj.value}</div>},
                {label: 'Net', render: (obj) => <div  ref={refElem5} className={obj.color}>{obj.net}</div>},
                {label: "Action", render: (obj) => obj.symbol === 'TOTAL' ? <div ref={refElem6}></div> : <div ref={refElem6}><Button color="error" onClick={(event)=>iconDeleteClick(event,obj)} variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><ClearIcon color="error" sx={{ fontSize: 32 }}/></Button></div>}
            ]  
            setTableConfig(tmpA)
            tmpA = []
            let totalcost = 0
            let totalnet = 0
            let totalV = 0
            let txtclr = ""
            let txtnet = ""
            _.forEach(positions,position => {
                let tmpP = ''
                let tmpV = ''
                // console.log(prices)
                if (`${position.symbol}-USD` in prices){
                    tmpP = prices[`${position.symbol}-USD`]
                } else {
                    tmpP = prices[`${position.symbol}/USD`]
                }
                tmpV = parseFloat(tmpP)*parseFloat(position.position)
                totalV = totalV + tmpV
                let net = parseFloat(position.position)*parseFloat(tmpP) - parseFloat(position.cost)
                totalnet = totalnet + net
                totalcost = totalcost + parseFloat(position.cost)
                let tmp3 = (Math.round(parseFloat(position.cost)*100)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                let totcost = `$${tmp3}`
                let tmp = 0
                if (net > 0){
                    txtclr = 'green-text'
                    tmp = (Math.round(net*100)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    txtnet = `+$${tmp}`
                } else {
                    txtclr = 'red-text'
                    tmp = (Math.round(net*100)/100*(-1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    txtnet = `-$${tmp}`
                }
                tmpV=(Math.round(tmpV*100)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                tmpA.push({
                    symbol : `${position.symbol}`,
                    cost : `${totcost}`,
                    position : `${position.position.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                    value: `$${tmpV}`,
                    net: txtnet,
                    color: txtclr
                })
            })
            let tmp3 = (Math.round(totalcost*100)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            let totcost = `$${tmp3}`
            if (totalnet > 0) {
                txtclr = 'green-text'
                tmp = (Math.round(totalnet*100)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                txtnet = `+$${tmp}`
            } else {
                txtclr = 'red-text'
                tmp = (Math.round(totalnet*100)/100*(-1)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                txtnet = `-$${tmp}`
            }
            let tmpVal = values
            if (!isNaN(totalV)) {
              setWaiting(false)
              const today = new Date(Date.now())
              const tsstr = `${today.toLocaleDateString()}-${today.toLocaleTimeString()}`
              let tmpD = valuedates
              tmpD.push(tsstr)
              if (tmpD.length > 100) {
                  tmpD.shift()
              }
              setValuedates(tmpD)
              
              tmpVal.push(totalV)
              if (tmpVal.length > 100) {
                  tmpVal.shift()
              }
              if (tmpVal[0]===0){
                  tmpVal.shift()
                  tmpD.shift()
                  setValuedates(tmpD)
              }
              
              setValues(tmpVal)
            }
            totalV=(Math.round(totalV*100)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            tmpA.push({
                symbol : 'TOTAL',
                cost : `${totcost}`,
                position : '-',
                value: `$${totalV}`,
                net: `${txtnet}`,
                color: txtclr
            })
            
            setTableData(tmpA)

        // }
        // let x = krs.length
        // let z = cbs.length
        // console.log(prices)

        let tmpcharts = []
        const options = {
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false //this will remove only the label
                    }
                }
            },
            responsive: true,
            plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: `Portfolio Value`,
            },
            },
        };

        const labels = valuedates;

        const data = {
            labels,
            datasets: [
            {
                label: 'Value',
                data: values,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                radius: 0,
                hitRadius: 5
            }
            ],
        };
        tmpcharts.push([options, data])
        setCharts(tmpcharts)

      },[prices])

      const addNewPosition = async (event) => {
        event.preventDefault()
        const pos = {symbol: event.target[0].value,cost: event.target[1].value,position: event.target[2].value}
        event.target.reset()
        await addPosition(pos)
      }

      useEffect(()=>{
        setWidth1(refElem1.current ? `${refElem1.current.clientWidth+10}px` : 'auto')
      },[refElem1.current])

      useEffect(()=>{
        setWidth2(refElem2.current ? `${refElem2.current.clientWidth+10}px` : 'auto')
      },[refElem2.current])

      useEffect(()=>{
        setWidth3(refElem3.current ? `${refElem3.current.clientWidth+10}px` : 'auto')
      },[refElem3.current])
      
      useEffect(()=>{
        setWidth4(refElem4.current ? `${refElem4.current.clientWidth+10}px` : 'auto')
      },[refElem4.current])

      useEffect(()=>{
        setWidth5(refElem5.current ? `${refElem5.current.clientWidth+10}px` : 'auto')
      },[refElem5.current])

      useEffect(()=>{
        setWidth6(refElem6.current ? `${refElem6.current.clientWidth+10}px` : 'auto')
      },[refElem6.current])
  

  const renderedItems = charts.map((item, index)=>{

    return (
        <div key={index}>
            <Line  options={charts[index][0]} data={charts[index][1]} />
        </div>
    )
})

    return (
      <div>
              
      <div >
          {waiting ? 
            <Box sx={{ display: 'flex' }}>
              <div style={{ justifyContent: 'center',display: 'flex'}}> <CircularProgress style={{marginTop: '50px'}} /> </div> 
            </Box>
            :
            <div style={{marginTop: '30px',padding: '0 10px', display: 'flex', flexDirection: window.innerWidth > 800 ? 'row' : 'column'}}>
              <div style={{width: window.innerWidth > 800 ? '60vw' : 'auto'}}>
                  {renderedItems}
              </div>
              <div>
                <Table data={tableData} config={tableConfig} keyFn={keyFn}/>
                <div style={{marginBottom: '50px'}}>
                  <form autoComplete="off" onSubmit={(event) => addNewPosition(event)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%', border: '1px solid black', borderRadius: '15px'}} >
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}} >
                      <TextField required inputProps={{min: 0, style: { textAlign: 'center' }}}  sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" placeholder="Symbol" style={{marginTop: '7px', marginBottom: '7px', width: width1}}/>
                      <TextField required  type='number' inputProps={{step: 0.000001, min: 0, style: { textAlign: 'center' }}}  sx={{"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {display: "none"},"& input[type=number]": {MozAppearance: "textfield"},".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" placeholder="Cost" style={{marginTop: '7px', marginBottom: '7px', width: width2}}/>
                      <TextField required  type='number' inputProps={{step: 0.000001, min: 0, style: { textAlign: 'center' }}}  sx={{"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {display: "none"},"& input[type=number]": {MozAppearance: "textfield"},".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" placeholder="Position" style={{marginTop: '7px', marginBottom: '7px', width: width3}}/>
                      <TextField disabled inputProps={{min: 0, style: { textAlign: 'center' }}}  sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" style={{marginTop: '7px', marginBottom: '7px', width: width4}}/>
                      <TextField disabled inputProps={{min: 0, style: { textAlign: 'center' }}}  sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" style={{marginTop: '7px', marginBottom: '7px', width: width5}}/>
                      <div style={{width: width6, minWidth: width6, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Button variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><AddIcon  sx={{ fontSize: 32 }}/></Button></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
      </div>
      
</div>

    )
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
          positions: state.positions
         }
}

export default withRouter(connect(mapStateToProps,{getPositions,removePosition, addPosition })(Crypto))