import React from "react";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

function ProfileCard(props) {

    

	return (
		<div style={{margin: '8px'}}>
            <Card sx={{ width: 175, height: 250, border: `3px solid ${props.usercolor}`}} >
                <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    { props.photo 
                        ? <Avatar alt={props.name} src={props.photo} onClick={()=>props.onClick(props.initials)} className="imgav" style={{marginTop: '10%', marginLeft: 'auto', marginRight: 'auto'}} sx={{ bgcolor: `${props.usercolor}`, width: 100, height: 100  }}></Avatar>
                        : <Avatar onClick={()=>props.onClick(props.initials)} className="imgav" style={{marginTop: '10%', marginLeft: 'auto', marginRight: 'auto'}} sx={{ bgcolor: `${props.usercolor}`, width: 100, height: 100 , fontSize: 36 }}>{props.initials}</Avatar>
                    }
                    <Typography style={{textAlign: 'center', marginTop: '10px'}} sx={{ fontSize: 17 }} color="text.secondary" gutterBottom>
                    {props.name}
                    </Typography>
                </CardContent>
                <CardActions>
                {
                props.initials !== '+' ?
                <div style={{display: 'flex', flexDirection: 'row', margin: '0 auto'}}>
                    <IconButton onClick={(event)=>props.onEdit(event,props)} aria-label="delete">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={()=>props.onRemove(props.famid)} style={{margin: '0 auto'}} aria-label="delete">
                        <ClearIcon />
                    </IconButton>
                </div>
                : null}
                </CardActions>
            </Card>
		</div>
	);
}

export default ProfileCard;