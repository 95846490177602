import {GET_POSITIONS, ADD_POSITION, REMOVE_POSITION} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case GET_POSITIONS:
            return action.payload || false;
        case ADD_POSITION:
            return action.payload || false;
        case REMOVE_POSITION:
            return action.payload || false;
        default:
            return state;
    }
}