import {ADD_GUEST,GET_GUESTS,REMOVE_GUEST} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_GUEST:
            return action.payload || false;
        case GET_GUESTS:
            return action.payload || false;
        case REMOVE_GUEST:
            return action.payload || false;
        default:
            return state;
    }
}