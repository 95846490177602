export const nflTeamNameMap = {
    ARI:"Arizona Cardinals",
    ATL:"Atlanta Falcons",
    BAL:"Baltimore Ravens",
    BUF:"Buffalo Bills",
    CAR:"Carolina Panthers",
    CHI:"Chicago Bears",
    CIN:"Cincinnati Bengals",
    CLE:"Cleveland Browns",
    DAL:"Dallas Cowboys",
    DEN:"Denver Broncos",
    DET:"Detroit Lions",
    GNB:"Green Bay Packers",
    HOU:"Houston Texans",
    IND:"Indianapolis Colts",
    JAX:"Jacksonville Jaguars",
    KAN:"Kansas City Chiefs",
    LVR:"Las Vegas Raiders",
    LAR:"Los Angeles Rams",
    LAC:"Los Angeles Chargers",
    MIA:"Miami Dolphins",
    MIN:"Minnesota Vikings",
    NWE:"New England Patriots",
    NOR:"New Orleans Saints",
    NYG:"New York Giants",
    NYJ:"New York Jets",
    PHI:"Philadelphia Eagles",
    PIT:"Pittsburgh Steelers",
    SFO:"San Francisco 49ers",
    SEA:"Seattle Seahawks",
    TAM:"Tampa Bay Buccaneers",
    TEN:"Tennessee Titans",
    WSH:"Washington Commanders",
    "Arizona Cardinals": "ARI",
    "Atlanta Falcons":"ATL",
    "Baltimore Ravens":"BAL",
    "Buffalo Bills":"BUF",
    "Carolina Panthers":"CAR",
    "Chicago Bears":"CHI",
    "Cincinnati Bengals":"CIN",
    "Cleveland Browns":"CLE",
    "Dallas Cowboys":"DAL",
    "Denver Broncos":"DEN",
    "Detroit Lions":"DET",
    "Green Bay Packers":"GNB",
    "Houston Texans":"HOU",
    "Indianapolis Colts":"IND",
    "Jacksonville Jaguars":"JAX",
    "Kansas City Chiefs":"KAN",
    "Las Vegas Raiders":"LVR",
    "Los Angeles Rams":"LAR",
    "Los Angeles Chargers":"LAC",
    "Miami Dolphins":"MIA",
    "Minnesota Vikings":"MIN",
    "New England Patriots":"NWE",
    "New Orleans Saints":"NOR",
    "New York Giants":"NYG",
    "New York Jets":"NYJ",
    "Philadelphia Eagles":"PHI",
    "Pittsburgh Steelers":"PIT",
    "San Francisco 49ers":"SFO",
    "Seattle Seahawks":"SEA",
    "Tampa Bay Buccaneers":"TAM",
    "Tennessee Titans":"TEN",
    "Washington Commanders":"WSH",
    }

export const nflTeamNames = [
    "Arizona Cardinals",
    "Atlanta Falcons",
    "Baltimore Ravens",
    "Buffalo Bills",
    "Carolina Panthers",
    "Chicago Bears",
    "Cincinnati Bengals",
    "Cleveland Browns",
    "Dallas Cowboys",
    "Denver Broncos",
    "Detroit Lions",
    "Green Bay Packers",
    "Houston Texans",
    "Indianapolis Colts",
    "Jacksonville Jaguars",
    "Kansas City Chiefs",
    "Las Vegas Raiders",
    "Los Angeles Rams",
    "Los Angeles Chargers",
    "Miami Dolphins",
    "Minnesota Vikings",
    "New England Patriots",
    "New Orleans Saints",
    "New York Giants",
    "New York Jets",
    "Philadelphia Eagles",
    "Pittsburgh Steelers",
    "San Francisco 49ers",
    "Seattle Seahawks",
    "Tampa Bay Buccaneers",
    "Tennessee Titans",
    "Washington Commanders",
]

export const nflDivisionMap = {
    ARI:"NFCW",
    ATL:"NFCS",
    BAL:"AFCN",
    BUF:"AFCE",
    CAR:"NFCS",
    CHI:"NFCN",
    CIN:"AFCN",
    CLE:"AFCN",
    DAL:"NFCE",
    DEN:"AFCW",
    DET:"NFCN",
    GNB:"NFCN",
    HOU:"AFCS",
    IND:"AFCS",
    JAX:"AFCS",
    KAN:"AFCW",
    LVR:"AFCW",
    LAR:"NFCW",
    LAC:"AFCW",
    MIA:"AFCE",
    MIN:"NFCN",
    NWE:"AFCE",
    NOR:"NFCS",
    NYG:"NFCE",
    NYJ:"AFCE",
    PHI:"NFCE",
    PIT:"AFCN",
    SFO:"NFCW",
    SEA:"NFCW",
    TAM:"NFCS",
    TEN:"AFCS",
    WSH:"NFCE",
}

export const nflDivisionList = {
    "NFCN": ['CHI','MIN','GNB','DET'],
    "NFCE": ['DAL','NYG','PHI','WSH'],
    "NFCS": ['NOR','CAR','ATL','TAM'],
    "NFCW": ['ARI','LAR','SFO','SEA'],
    "AFCN": ['BAL','CIN','CLE','PIT'],
    "AFCE": ['BUF','MIA','NYJ','NWE'],
    "AFCS": ['HOU','IND','JAX','TEN'],
    "AFCW": ['DEN','KAN','LVR','LAC']
}

export const nflConferenceList = {
    "NFC": ['CHI','MIN','GNB','DET','DAL','NYG','PHI','WSH','NOR','CAR','ATL','TAM','ARI','LAR','SFO','SEA'],
    "AFC": ['BAL','CIN','CLE','PIT','BUF','MIA','NYJ','NWE','HOU','IND','JAX','TEN','DEN','KAN','LVR','LAC']
}