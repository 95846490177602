import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {Link,withRouter} from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GroupIcon from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import MenuIcon from '@mui/icons-material/Menu';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import IconButton from '@mui/material/IconButton';
import { getFamily, getSettings, setSetting } from '../actions';
import Clock from 'react-live-clock';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Typography } from '@mui/material';

const Header = ({auth, settings, family, getFamily,getSettings, setSetting }) => {


    const [open, setOpen] = React.useState(false);
    const [date, setDate] = useState(new Date())
    const [displayDate, setDisplayDate] = useState('')
    const [city, setCity] = useState('New York City')
    const [weatherData, setWeatherData] = useState(null);
    const [weatherTemp, setWeatherTemp] = useState('')
    const [weatherIcon, setWeatherIcon] = useState('')
    const [weatherDesc, setWeatherDesc] = useState('')
    const [tosignin, setTosignin] = useState(true)

    const months=['January','February','March','April','May','June','July','August','September','October','November','December']
    const days=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

    useEffect(()=>{
      // if (auth) {
      //   getSettings()
      // }
      var timer = setInterval(()=>{
        setDate(new Date())
        fetchData()
      }, 1000*60*5 )
      return function cleanup() {
          clearInterval(timer)
      }
      
    },[])

    useEffect(()=>{
      if (auth) {
        getSettings()
        setTosignin(false)
      }
    },[auth])

    useEffect(()=>{
      if (settings) {
        setCity(settings.city)
      }
    },[settings])

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=imperial&appid=c366e3cf384798447f8d8cedaaae159e`
        );
        setWeatherData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(()=>{
      fetchData()
    },[city])

    useEffect(()=>{
      if (weatherData) {
      // console.log(weatherData)
      setWeatherTemp(Math.round(weatherData.main.temp))
      setWeatherIcon(`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`)
      setWeatherDesc(weatherData.weather[0].main)
      }
    },[weatherData])

    useEffect(()=>{
      const tmp = `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} `
      setDisplayDate(tmp)
    },[date])

    const location = useLocation();

    const isSigninPage = location.pathname.includes('signin');
    const isPaymentPage = location.pathname.includes('payment');
    const isHomePage = location.pathname === '/';

    const toggleDrawer = (newOpen) => () => {
        const x = document.getElementById('headerbtn')
        x.blur()
        setOpen(newOpen);
    };

    const drawerWidth = 100
    
    const DrawerList = (
        <Box sx={{ width: drawerWidth }} role="presentation" onClick={toggleDrawer(false)}>
          { auth ? 
          <List >
              <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/family" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <GroupIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Family"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem>
              {settings?.calendar ? <ListItem style={{padding: '7px 0'}}  >
                <ListItemButton component={Link} to="/calendar" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <CalendarTodayIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Calendar"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
              {settings?.tasks ? <ListItem style={{padding: '7px 0'}}  >
                <ListItemButton component={Link} to="/todos" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <FormatListBulletedIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Tasks"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
              {settings?.lists ? <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/lists" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <LocalGroceryStoreIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Lists"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
              {settings?.countdown ? <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/countdown" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <HourglassBottomIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Countdown"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
              {settings?.sports ? <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/sports" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <ScoreboardIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Sports"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
              {settings?.photos ? <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/photos" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <PhotoSizeSelectActualIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Photos"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
              {settings?.crypto ? <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/crypto" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <CurrencyBitcoinIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Crypto"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null}
          </List>
          : null}
          <List style={auth ? { position: "absolute", bottom: '0', margin:'0 auto', left: 0, right: 0, width: '100%' } : null}>
              { auth ? 
              <ListItem style={{padding: '7px 0'}} >
                <ListItemButton component={Link} to="/settings" style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      <SettingsIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Settings"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem> : null }
              <ListItem disablePadding style={{display: 'inline-block'}}>
                <ListItemButton component={auth ? "a" : Link} to={auth ? "/api/logout" : "/signin"} style={{padding: 0}}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon style={{display: 'flex',justifyContent: 'center'}}>
                      {auth ? <LogoutIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/> : <LoginIcon fontSize={window.innerWidth > 800 ? "large" : "medium"} style={{margin: '0 auto'}}/>}
                    </ListItemIcon>
                    {auth ? <ListItemText primary={"Logout"} style={{textAlign: 'center'}}/> : <ListItemText primary={"Login"} style={{textAlign: 'center'}}/> }
                  </div>
                </ListItemButton>
              </ListItem>
          </List>
        </Box>
      );


      return (
        isHomePage | isSigninPage | isPaymentPage
        ? 
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '5vh', position: 'fixed', top: 0, width: window.innerWidth > 800 ? '80%' : '100%', backgroundColor: 'white', padding: '0 5px' }}>
          <Button variant="standard" component={Link} to="/" sx={{fontFamily: '"Slabo 27px", serif', fontSize: window.innerWidth > 800 ? '30px' : '20px', textTransform: 'none', padding: 0}} style={{ backgroundColor: 'transparent' }}>MyDisplay</Button><Typography style={{fontFamily: '"Slabo 27px", serif', fontSize: window.innerWidth > 800 ? '30px' : '20px', color: 'rgb(25,118,211)'}}>.io</Typography>
          {isSigninPage | isPaymentPage ? null : <Button variant='contained' component={Link} to={tosignin ? "/signin" : "/payment"} style={{marginLeft: 'auto', textTransform: 'none'}}>Unlock Your Display</Button>}
        </div>
        :
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '5vh'}}>
            <IconButton style={{marginLeft: '30px',marginRight: '10px'}} id="headerbtn" aria-label="toggle" color="primary" onClick={toggleDrawer(true)}>
                <OtherHousesIcon fontSize="large" style={{transform: 'scale(2.3)', marginRight: '20px'}}/>
            </IconButton>
            {window.innerWidth > 800 ? 
              <div style={{display: 'flex',flexDirection: 'column'}}>
                <Clock ticking={true} format={'h:mm a'} noSsr style={{fontFamily: '"Slabo 27px", serif', fontSize: '40px'}} />
                <span style={{marginTop: '-10px', fontFamily: '"Slabo 27px", serif', fontSize: '20px'}}>{displayDate}</span>
              </div>
            : null}
            <div style={{marginLeft: 'auto', marginRight: '25px'}}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <img src={weatherIcon} />
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span style={{fontFamily: '"Slabo 27px", serif'}}>{weatherTemp}&#176;</span>
                  <span style={{fontFamily: '"Slabo 27px", serif'}}>{weatherDesc}</span>
                </div>
              </div>
            </div>
            {auth ? null : 
            <div style={{marginLeft: 'auto'}}>
                {isSigninPage ? null : <Button component={Link} to="/signin" className="black-text" style={{border:'2px solid black',margin:'5px',fontFamily: '"Roboto", sans-serif',textTransform: 'none'}}>Sign In</Button>}
            </div>
            }
            <Drawer sx={{width: drawerWidth,flexShrink: 0,'& .MuiDrawer-paper': {width: drawerWidth, boxSizing: 'border-box',}}} open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
      )








    // state = {
    //     selectedOption: null
    //   };
      
    
    //   handleChange = selectedOption => {
    //     if (selectedOption.value === 'logout') {
    //         let logout = document.getElementById("logoutbtn")
    //         logout.click()
    //     } 
    //   };



    // renderContent() {
    //     const options = [
    //         { value: "settings", label: "Settings" },
    //         { value: "logout", label: "Logout" }
    //       ];
    //       const customStyles = {
    //         control: (provided, state) => ({
    //           ...provided,
    //           border: 0,
    //           boxShadow: 'none',
    //           width: '130px',
    //         //   fontFamily: "Gruppo",
    //           fontSize: "17px",
    //           margin: "-2px 0px 0px 0px"
    //         }),
    //         option: (provided, state) => ({
    //             ...provided,
    //             border: 'none',
    //             // fontFamily: "Gruppo",
    //             fontSize: "17px"
    //           }),
              
    //       };
    //       const { selectedOption } = this.state;
    //     switch (this.props.auth) {
    //         case null:
    //             return;
    //         case false:
    //             return (
                    
    //                     this.props.location.pathname!=='/signin'? 
    //                     <li><a href="/signin" className="black-text" style={{fontFamily: "Gruppo",fontSize: "17px",border:'2px solid black',margin:'5px'}}>Sign In</a></li>
    //                     :null
                    
                    
    //             )
    //         default:
    //             let headerArr = []
    //             headerArr.push(<li key="5"><Select  value={selectedOption}
    //             options={options}
    //             onChange={this.handleChange}
    //             isSearchable={false}
    //             styles={customStyles}
    //             placeholder={<div className="col black-text right" style={{height: "100%" , fontSize: "17px"}} >Account</div>} 
    //             // fontFamily="Gruppo"
    //             blurInputOnSelect={true}
    //             closeMenuOnSelect={false} menuPortalTarget={document.body} 
    //             components={{
    //                 IndicatorSeparator: () => null,
    //             }}
    //             /></li>)
    //             return headerArr
    //     }
    // }

    // render() {
    //     return (
    //         <nav id="headerID" className="z-depth-0 " style={{width:"100%",position: "relative", left: "0%"}}>
    //             <div className="nav-wrapper white " style={{padding: "0 0%"}}>
    //                 <Link 
    //                     to={this.props.auth ? '/home' : '/'} 
    //                     style={{fontSize: "40px"}}
    //                     className="left brand-logo black-text">
    //                     Header
    //                 </Link>
    //                 {/* <a id="logoutbtn" style={{display: "none"}} className="black-text"  href="api/logout"></a>
    //                 <ul className="right" style={{overflow: "visible", padding: "0 0%",margin: "0"}}>
    //                     {this.renderContent()}
    //                 </ul> */}
    //             </div>
    //         </nav>
    //     )
    // }
}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            family: state.family, 
            settings: state.settings
           }
}

export default withRouter(connect(mapStateToProps,{getFamily,getSettings,setSetting })(Header));