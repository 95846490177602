import {ADD_EVENT,FETCH_EVENTS,REMOVE_EVENT,EDIT_EVENT} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_EVENT:
            return action.payload || false;
        case FETCH_EVENTS:
            return action.payload || false;
        case REMOVE_EVENT:
            return action.payload || false;
        case EDIT_EVENT:
            return action.payload || false;
        default:
            return state;
    }
}