import {ADD_COUNTDOWN,GET_COUNTDOWN,REMOVE_COUNTDOWN} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_COUNTDOWN:
            return action.payload || false;
        case GET_COUNTDOWN:
            return action.payload || false;
        case REMOVE_COUNTDOWN:
            return action.payload || false;
        default:
            return state;
    }
}