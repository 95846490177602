import React, {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import Timers from './Timers'
import { addCountdown, getCountdowns, removeCountdown } from '../actions';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';

const Countdown = ({auth,mastercountdown,addCountdown, getCountdowns, removeCountdown}) => {
    // const [dates, setDates] = useState([{title: 'New Year', date: '01-01-2025 00:00:00'}, {title: 'test', date: '07-10-2024 00:00:00'}])
    const [dates, setDates] = useState([])
    const [open, setOpen] = useState(false)
    const [eventEndDate, setEventEndDate] = useState(moment());
    const [eventEndTime, setEventEndTime] = useState(moment());
    const [checked, setChecked] = useState(false);

    useEffect(()=>{
      getCountdowns()
    },[])

    useEffect(()=>{
      setDates(mastercountdown)
    },[mastercountdown])

    const openAdd = (event) => {
      event.target.blur()
      setOpen(true)
    }

    const createNewCountdown = async (event) => {
      event.preventDefault()
      const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
      setOpen(false)
      const x = document.querySelector('[name="famname"]').value
      const cdDate = `${months[eventEndDate.month()]} ${eventEndDate.date()}, ${eventEndDate.year()} ${eventEndTime.hour()}:${eventEndTime.minutes()}:00`
      await addCountdown({title: x, date: cdDate})
      setEventEndDate(moment())
      setEventEndTime(moment())
    }

    const closePicker3 = () => {
      let x = document.getElementById("enddatepicker")
      setTimeout(() => {
        // x.blur();
      }, 1);
    }
    const closePicker4 = () => {
      let x = document.getElementById("endtimepicker")
      setTimeout(() => {
        // x.blur();
      }, 1);
    }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: window.innerWidth < 800 ? '95%' : 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const handleAddClose = () => setOpen(false);

    const deleteTimer = async (event, idx) => {
      await removeCountdown({idx})
      event.target.blur()
    }

    return(
      <div style={{marginTop: '40px'}}>

        { dates ? dates.map((item,index)=>{
        return (
          <div key={index} style={{width: '60vw', margin: '20px auto'}}>
            <span style={{fontSize: window.innerWidth > 800 ? '24px' : '16px', padding: '0 15px', display: 'table', backgroundColor: '#FFF',textAlign: 'center',position: 'relative',zIndex: 1000,margin: '0 auto'}}>
              Days Until {item.title}
            </span>
            <div style={{display:'flex', flexDirection: 'column', borderRadius: '15px', padding: '10px',border: '3px solid #1a76d3',position: 'relative',marginTop: window.innerWidth > 800 ? '-20px' : '-14px'}}>
              <Timers eventEndTime={Date.parse(item.date)}/>
              <Button onClick={(event)=>deleteTimer(event,index)} style={{marginLeft: 'auto',marginTop: '15px',textTransform: 'none', borderRadius: '15px'}} color="error" variant="outlined"><ClearIcon sx={{ fontSize: 32 }}/></Button>
            </div>
          </div> )
        }) : null}

        <div style={{borderRadius: '15px', border: '3px solid black', width: '60vw', height: '10vw', margin: '40px auto'}}>
          <Button disableRipple variant="outlined" onClick={(event)=>{openAdd(event)}} style={{margin: '0 auto', width: '100%', height: '100%', borderRadius:'15px'}} sx={{fontSize: '18px', padding: 0, marginRight: '7px', border: 'none', outline: 'none', textTransform: 'none'}}>Create a New Timer +</Button>
        </div>



        <Modal open={open} onClose={handleAddClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} >
                <form noValidate autoComplete="off" onSubmit={(event) => createNewCountdown(event)} >
                    <h5 style={{textAlign: 'center', fontSize: '18', marginTop: '0px'}}>Add a New Countdown Timer!</h5>
                    <TextField name='famname' sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}  variant="standard" placeholder="Title" style={{marginTop: '7px', marginBottom: '7px', width: '100%'}}/>
                    <div style={{display: 'inline-flex', flexDirection: 'row', paddingTop: '7px', paddingBottom: '7px'}}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="End Date"
                          name="enddate"
                          value={eventEndDate}
                          // defaultValue={eventStartDate}
                          onClose={()=>closePicker3()}
                          onChange={(newValue) => setEventEndDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          // sx={{"& .MuiOutlinedInput-root": {paddingLeft: '8px'}}}
                          slotProps={{openPickerButton:{id: "enddatepicker"}, textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}}
                        />
                        <div style={{margin:'0 7px'}}></div>
                        <TimePicker name="endtime" onClose={()=>closePicker4()} onChange={(newValue) => setEventEndTime(newValue)} label="End Time" slotProps={{openPickerButton:{id: "endtimepicker"}, textField: {variant: "standard",sx:{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}}} defaultValue={eventEndTime} value={eventEndTime} timeSteps={{ hours: 1, minutes: 5 }}/>
                      </LocalizationProvider>
                    </div>
                    <div style={{paddingTop: '15px'}}>
                        <Button style={{textTransform: 'none'}} onClick={()=>setOpen(false)} variant='outlined' color='error'>Cancel</Button>
                        <Button variant='contained' type="submit" style={{float: 'right', textTransform: 'none'}}>Add</Button>
                    </div>
                </form>
            </Box>
        </Modal>
      </div>
    )
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
          mastercountdown: state.mastercountdown, 
         }
}

export default withRouter(connect(mapStateToProps,{addCountdown, getCountdowns, removeCountdown})(Countdown))