import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import { getLists, addListItem, removeListItem, toggleListItem, clearListItem, deleteList, createList, editListItem, moveList } from '../actions';
import { useState } from "react";
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import { v4 as uuidv4 } from 'uuid';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import IconButton from '@mui/material/IconButton';

const projectFont = '"Slabo 27px", serif'
// const projectFont = '"BioRhyme", serif'

const FormCheckBox = ({ item, index, status, memid, toggleListItem , removeListItem, editListItem}) => {
  const [checked, setChecked] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [editlabel, setEditlabel] = useState(false);
  const [label, setLabel] = useState('');
  const [scroll, setScroll] = useState(false);
  const [conwidth, setConwidth] = useState(0);
  const spanRef = useRef()

  useEffect(()=>{
    setTimeout(function (){
      setConfetti(false)
    }, 4000); 
    
  },[confetti])

  useEffect(()=>{
      setChecked(status)
      setLabel(item)    
      const t = document.getElementById('tf')
      setConwidth(t.offsetWidth)
  },[])

  useEffect(()=>{
    if (spanRef.current) {
    if (spanRef.current.offsetWidth > conwidth) {
      setScroll(true)
    }
  }
  },[label])

  useEffect(()=>{
    setChecked(status)
    setLabel(item)
  },[item])

  const deleteTask = async (event, id, idx) => {
    await removeListItem({id, idx})
  }

  const toggleCheck = () => {
    const x = document.getElementsByClassName(`${memid}-chbx`)

    setChecked(!checked);

    let checker = arr => arr.every(Boolean);
    let alldone = []
    if ( x.length > 0) {
      setTimeout(function (){
        for (let step = 0; step < x.length; step++) {
          alldone.push(x[step].children[0].checked)
        }
        toggleListItem({id: memid, values:alldone})
        if (checker(alldone)) {
          setConfetti(true)
        }
      }, 5); 
    }
  }

  const labelTouched = (event) => {
    setEditlabel(true)
  }

  const labelChanged = (event) => {
    setLabel(event.target.value)
  }

  const doneEdit = async (event) => {
    if (label != item) {
      await editListItem({label: label, idx: index, id: memid})
    }
    setEditlabel(false)
  }

  const doneEdit2 = async (event) => {
    setEditlabel(false)
  }
  
  return (
    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      <Checkbox className={`${memid}-chbx ${memid}-${index}`} checked={checked} onClick={toggleCheck}/>
      <div style={{marginLeft: '7px', width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center'}} id='tf'>
        {/* <TextField disabled value={item} fullWidth sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, ".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" placeholder="Task" style={{marginTop: '7px', marginBottom: '7px',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}}/> */}
        {editlabel ? <TextField inputProps={{}}   onClick={(event)=>labelTouched(event)} onBlur={(event)=>doneEdit(event)} onChange={(event)=>labelChanged(event)} disabled={!editlabel} inputRef={(input) => editlabel === true && input?.focus()}  value={label} fullWidth sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, ".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true}} name='todoname' variant="standard" placeholder="Task" style={{marginTop: '7px', marginBottom: '7px',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}}/>
        : <div style={{display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%'}}>
            <span className={scroll & !checked ? 'marquee cap' : 'cap'} style={{padding: scroll & !checked ? '0 30px' : '0 0', whiteSpace: 'nowrap',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}} ref={spanRef} onClick={(event)=>labelTouched(event)}>{label}</span>
            {scroll ? <span className={scroll & !checked ? 'marquee cap' : 'cap'} style={{padding: scroll & !checked ? '0 30px' : '0 0', whiteSpace: 'nowrap',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}} onClick={(event)=>labelTouched(event)}>{label}</span> : null}
          </div>
        }
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {
          !editlabel 
            ? <Button variant="outlined" color='error' onClick={(event)=>deleteTask(event,memid, index)} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', border: 'none'}} ><ClearIcon sx={{ fontSize: 32 }}/></Button>
            : <Button variant="outlined" onClick={(event)=>doneEdit2(event)} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', border: 'none'}} ><CheckIcon sx={{ fontSize: 32 , color: 'green'}}/></Button>
        }
      </div>
      {confetti ? <Fireworks autorun={{ speed: 3 }} /> : null}
    </div>
  )
}

const Groceries = ({auth, masterlists, getLists, addListItem, removeListItem, toggleListItem, clearListItem, deleteList, createList, editListItem, moveList }) => {
    const [fam, setFam] = useState([])
    const [todos, setTodos] = useState({})
    const [opennewlist, setOpennewlist] = useState(false)

    useEffect(()=>{
      getLists()
    },[])

    useEffect(()=>{
      console.log(masterlists)
      if (masterlists) {
        var count = 0;
        for(var prop in masterlists) {
            if(masterlists.hasOwnProperty(prop))
                ++count;
        }
        var keys = Object.keys(masterlists);
        let tmp = []
        for (let i = 0; i < count; i++) {
          tmp.push({id: keys[i], name: masterlists[keys[i]].name})
        }
        setFam(tmp)

        // for (let i = 0; i < count; i++) {
        //   console.log(keys[i])
        //   var elem = document.getElementById(`${keys[i]}-new`);
        //   if (elem) {
        //     var topPos = elem.offsetTop;
        //     console.log(topPos)
        //     document.getElementById(`${keys[i]}-list`).scrollTop = topPos + 20;
        //   }
        // }
      }
      setTodos(masterlists)
      
    }, [masterlists])


    const addNewTask = async (event, id, name) => {
      event.preventDefault()
      const task = event.target[0].value
      event.target.reset();
      // console.log(task,id)
      await addListItem({task,id,name})
      await getLists()
    }

    const deleteTask = async (event, id, idx) => {
      await removeListItem({id, idx})
    }

    const clearAllTodos = async (event, id) => {
      await clearListItem({id})
      event.target.blur()
    }

    const deleteWholeList = async (event, id) => {
      await deleteList({id})
      event.target.blur()
    }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: window.innerWidth < 800 ? '90%' : 450,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const createNewList = (event) => {
      event.target.blur()
      setOpennewlist(true)
    }

    const submitNewList = async (event) => {
      event.preventDefault()
      // console.log('create new list called: ', event.target[0].value)
      const lst = {name: event.target[0].value, id: uuidv4()}
      await createList(lst)
      event.target.reset()
      setOpennewlist(false)
      event.target.blur()
    }
    
    const handleNewListClose = () => {
      setOpennewlist(false);
    }
    
    const todoArray = fam?.map((member,index) => {
      
      return (
        todos != null ? 
        <div key={member.id} style={{position: 'relative',zIndex: 20, borderRadius: '10px', border: '0.1em solid rgba(0,0,0,0.5)',flex: window.innerWidth > 800 ? '0 0 300px' : '0 0 250px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', margin: '0 10px', overflowY: 'scroll'}}>
          <div className='listcontainer' style={{overflow: 'auto'}}>
            <div style={{textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row', position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white', paddingLeft: 4, paddingRight: 4, backgroundClip: 'content-box'}}>
              <IconButton aria-label="move-left" size='large' onClick={()=>moveList({id: member.id, dir: 'left', idx: index})} style={{marginLeft: 5, marginTop: 5}}>
                <UndoIcon />
              </IconButton>
              <span style={{fontFamily: projectFont, fontSize: '30px', alignItems: 'center', display: 'grid', margin: '0 auto'}}>{member.name}</span>
              <IconButton aria-label="move-right" size='large' onClick={()=>moveList({id: member.id, dir: 'right', idx: index})} style={{marginLeft: 5, marginTop: 5}}>
                <RedoIcon />
              </IconButton>
            </div>
            <div id={`${member.id}-list`} style={{position: 'relative'}}>
              {todos[member.id]?.task.map((item,index) => {
                return (
                  <div key={index} style={{height: '60px',margin: '4px 7px', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '40px'}}>
                    <FormCheckBox key={index} status={todos[member.id].status[index]} item={todos[member.id].task[index]} memid={member.id} index={index} toggleListItem={toggleListItem} removeListItem={removeListItem} editListItem={editListItem}/>
                    {/* <Button variant="outlined" color='error' onClick={(event)=>deleteTask(event,member.id, index)} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', border: 'none'}} ><ClearIcon sx={{ fontSize: 32 }}/></Button> */}
                  </div>
                )
              }) 
              }
              
              <div id={`${member.id}-new`} style={{height: '40px',margin: '4px 7px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <form noValidate autoComplete="off" onSubmit={(event) => addNewTask(event,member.id,member.name)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%'}} >
                  <div style={{marginLeft: '7px', width: '100%'}} >
                    <TextField fullWidth sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: false }} name='todoname' variant="standard" placeholder="Enter Task ..." style={{marginTop: '7px', marginBottom: '7px'}}/>
                  </div>
                  <Button variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><AddIcon  sx={{ fontSize: 32 }}/></Button>
                </form>
              </div>
            </div>
          </div>
          <div style={{height: '60px', minHeight: '60px',marginLeft: '7px', marginRight: '7px', marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Button variant="outlined" style={{margin: '0 auto', width: '40%', height: '85%', borderRadius:'10px'}} onClick={(event)=>clearAllTodos(event,member.id)} color='error' sx={{fontSize: '18px', padding: 0, marginRight: '7px', textTransform: 'none'}}>Clear All</Button>
              <Button variant="contained" style={{margin: '0 auto', width: '40%', height: '85%', borderRadius:'10px'}} onClick={(event)=>deleteWholeList(event,member.id)} color='error' sx={{fontSize: '18px', padding: 0, marginRight: '7px', textTransform: 'none'}}>Delete List</Button>
          </div>
        </div>
      : null)
    })
    
    return(
      <div style={{paddingTop: '40px', paddingBottom: '20px', height: '85vh', width:  '100%' , display: 'flex', flexDirection: 'row', overflowX: 'scroll'}}>
          {todoArray}
          <div style={{borderRadius: '15px', border: `2px solid blue`, flex: '0 0 300px', height: '100%', margin: '0 10px', overflowY: 'scroll'}}>
          <Button disableRipple variant="outlined" onClick={(event)=>{createNewList(event)}} style={{margin: '0 auto', width: '100%', height: '100%', borderRadius:'10px'}} sx={{fontSize: '18px', padding: 0, marginRight: '7px', border: 'none', outline: 'none', textTransform: 'none'}}>Create a New List +</Button>
          </div>
          <Modal open={opennewlist} onClose={handleNewListClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} style={{padding: '8px'}}>
              <form noValidate autoComplete="off" onSubmit={(event) => submitNewList(event)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%'}} >
                <div style={{marginLeft: '7px', marginRight: '7px'}} >
                  <TextField fullWidth inputProps={{style: {paddingLeft: '7px'}}} sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}} InputProps={{ disableUnderline: true }} name='todoname' variant="outlined" placeholder="List Name ..." style={{marginTop: '7px', marginBottom: '7px'}}/>
                  <Button variant="outlined" style={{float: 'right'}} type="submit" sx={{fontSize: '24px', padding: 0, height: '40px', width: '140px', color: 'rgb(25,118,210)', textTransform: 'none'}}>Add<AddIcon  sx={{ fontSize: 24 }}/></Button>
                  <Button variant="outlined" sx={{fontSize: '24px', padding: 0, height: '40px', width: '140px', textTransform: 'none'}} color="error" onClick={()=>setOpennewlist(false)}>Cancel<ClearIcon  sx={{ fontSize: 24 }}/></Button>
                </div>
              </form>
            </Box>
          </Modal>
      </div>
    )
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
          masterlists: state.masterlists,
         }
}

export default withRouter(connect(mapStateToProps,{ getLists, addListItem, removeListItem, toggleListItem, clearListItem, deleteList, createList , editListItem, moveList})(Groceries))