import React, {useEffect,useState} from 'react'
import { getGuests,addGuest,removeGuest, getSettings, setSetting, getPhotos, addPhoto, removePhoto, setPhotoInfo } from '../actions'
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Switch from "react-switch";
import styled from "@emotion/styled";
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import UploadWidget from './UploadWidget';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme?.palette?.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme?.palette?.mode === 'light'
            ? theme?.palette?.grey[100]
            : theme?.palette?.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme?.palette?.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme?.palette?.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme?.transitions?.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  
const Settings = ({photo,photos, settings, getGuests, addGuest, removeGuest, masterguests, getSettings, setSetting, getPhotos, addPhoto, removePhoto, setPhotoInfo}) => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [city, setCity] = useState('New York City')
    const [pic, setPic] = useState('')
    const [url, setUrl] = useState('')

    const SCREENS = ['calendar','tasks','lists','countdown','sports','photos','crypto']

    useEffect(()=>{
        getGuests()
        getSettings()
        getPhotos()
    },[])

    useEffect(()=>{
        if (settings?.city) {
            setCity(settings.city)
        }
    },[settings])

    const changeSetting = async (event,obj) => {
        await setSetting({[obj]: event})
    }

    const deleteGuest = async (event,idx) => {
        await removeGuest({idx})
    }

    const addNewGuest = async (event) => {
        event.preventDefault()
        if ( emailError) {
            alert("Form is invalid! Please check the fields...");
            return
          }
        const email = event.target[0].value
        console.log(email)
        event.target.reset();
        setEmail('')
        await addGuest({email})
    }

    const handleEmailChange = e => {
        const regex = new RegExp('([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@(?:gmail|GMAIL)([\.])(?:com|COM)')
        setEmail(e.target.value);
        if (!regex.test(e.target.value)) {
          setEmailError("Invalid Gmail address");
        } else {
          setEmailError(false);
        }
      };

      const handleCityChange = e => {
        setCity(e.target.value);
      }

      const handlePicChange = e => {
        setPic(e.target.value);
      }

      const handleUrlChange = e => {
        setUrl(e.target.value);
      }

      const handleCityUpdate = (event) => {
        event.preventDefault()
        event.target.reset()
        setSetting({'city': event.target[0].value})
      }

      const addNewPic = async (event) => {
        event.preventDefault()
        event.target.reset()
        // setSetting()
        const tmp = {'name': event.target[0].value, 'url': event.target[1].value}
        await addPhoto(tmp)
      }
      useEffect(()=>{
        setPic('')
        setUrl('')
      }, [photos])

      const deletePic = (event,idx) => {
        removePhoto({idx})
      }

      const previewPic = (event,idx) => {
        // console.log(photos[idx].name)
      }

      const uploadPic = async (pic) => {
        if (typeof pic.url === 'string') {
          const tmp = {'name': pic.public_id.split('/')[1], 'url': pic.url}
          await addPhoto(tmp)
        }
      }

      useEffect(()=>{
        uploadPic(photo)
        setPhotoInfo(null)
      },[photo])

    return(
      settings ? 
      <div style={{marginTop: '40px', padding: '0 3%'}}>
        <Divider style={{marginBottom: '10px'}} textAlign="left">Enter City for Weather (No Abbreviations)</Divider>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <form noValidate autoComplete="off" onSubmit={(event) => handleCityUpdate(event)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%'}} >    
                <TextField 
                    value={city}
                    sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}      
                    variant="standard" 
                    fullWidth
                    onChange={handleCityChange}
                    placeholder="Enter City Name ..." 
                    style={{marginTop: '7px', marginBottom: '7px', width: '40%'}}
                />
                <Button variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><CheckIcon  sx={{ fontSize: 32 }}/></Button>
                <span style={{marginLeft: '20px', alignSelf: 'center'}}>(Current city: {settings?.city})</span>
            </form>
            
        </div>
        <Divider style={{marginBottom: '10px', marginTop: '50px'}} textAlign="left">Guest Account Access</Divider>
        <div>
            Invite a friend to your MyDisplay account (Currently only Gmail accounts are supported):
        </div>
        <div style={{height: '60px',margin: '4px 7px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <form noValidate autoComplete="off" onSubmit={(event) => addNewGuest(event)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%'}} >
                <div style={{width: '50%'}} >
                    <TextField 
                        value={email}
                        sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}  
                        onChange={handleEmailChange}
                        error={emailError}
                        helperText={emailError ? "Please enter a valid Gmail address" : ""} 
                        fullWidth    
                        variant="standard" 
                        placeholder="Enter Guest Email ..." 
                        style={{marginTop: '7px', marginBottom: '7px'}}
                    />
                </div>
                <Button variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><AddIcon  sx={{ fontSize: 32 }}/></Button>
            </form>
        </div>
        <ul>
            {
                masterguests?.map((item,index)=>{
                    return (
                        <li key={index}>
                            <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <span>{item}</span>
                                <Button style={{float: 'right'}}  variant="outlined" color='error' onClick={(event)=>deleteGuest(event,index)} sx={{fontSize: '20px', padding: 0, height: '30px', width: '30px', minWidth: '30px', marginRight: '7px', border: 'none'}} ><ClearIcon sx={{ fontSize: 20 }}/></Button>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
        <div>
            <span style={{fontWeight: 'bold', marginRight: '3px'}}>NOTE:</span>Each email address should only be associated with a single MyDisplay account!
        </div>


        <Divider style={{marginBottom: '10px',marginTop: '50px'}} textAlign="left">Select Which Screens to Include</Divider>
        {settings ?
            SCREENS.map((screen)=>{
                return (
                    <div key={screen} style={{display: 'flex', flexDirection: 'row', marginBottom: '2px'}}>
                        <span style={{width: '100px'}}>{screen}:</span>
                        <Switch name="adswitch" onColor='#1a76d3' onChange={(event)=>changeSetting(event,screen)} checked={settings[screen]} checkedIcon={false} uncheckedIcon={false} height={21} width={42}/>
                    </div>
                )
            })
        : null}


        <Divider style={{marginBottom: '10px', marginTop: '50px'}} textAlign="left">Add Photos</Divider>
        <div>
            Add the URL for a photo you'd like to display on your 'Photos' screen:
        </div>
        <div style={{height: '60px',margin: '4px 7px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <form noValidate autoComplete="off" onSubmit={(event) => addNewPic(event)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%'}} >
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} >
                    <TextField 
                        value={pic}
                        sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}  
                        onChange={handlePicChange}
                        fullWidth    
                        variant="standard" 
                        placeholder="Photo Name ..." 
                        style={{marginTop: '7px', marginBottom: '7px'}}
                    />
                    <TextField 
                        value={url}
                        sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important'},"& .MuiInput-underline:after": {borderBottomColor: "#1a76d3"}}}  
                        onChange={handleUrlChange}
                        fullWidth    
                        variant="standard" 
                        placeholder="Enter URL ..." 
                        style={{marginTop: '7px', marginBottom: '7px', marginLeft: '15px'}}
                    />
                </div>
                <Button variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><AddIcon  sx={{ fontSize: 32 }}/></Button>
            </form>
        </div>
        <div style={{margin: '8px auto', display: 'flex', flexDirection: 'row'}}>
          <span style={{marginRight: '10px'}}>Or upload your own image</span><UploadWidget />
        </div>
        <ul>
            {
                photos?.map((item,index)=>{
                    return (
                        <li key={index}>
                            <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <span>{item.name}</span>
                                <Tooltip
                                  placement="right-start"
                                  title={
                                    <>
                                      <img style={{width: '100px', height: '100px', objectFit: 'contain'}} src={item.url} />
                                    </>
                                  }
                                >
                                <Button style={{float: 'right'}}  variant="outlined" onClick={(event)=>previewPic(event,index)} sx={{fontSize: '20px', padding: 0, height: '30px', width: '30px', minWidth: '30px', border: 'none'}} ><SearchIcon sx={{ fontSize: 20 }}/></Button>
                                </Tooltip>
                                <Button style={{float: 'right'}}  variant="outlined" color='error' onClick={(event)=>deletePic(event,index)} sx={{fontSize: '20px', padding: 0, height: '30px', width: '30px', minWidth: '30px', border: 'none'}} ><ClearIcon sx={{ fontSize: 20 }}/></Button>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
      </div>
      :
      <div style={{ justifyContent: 'center',display: 'flex'}}> <CircularProgress style={{marginTop: '50px'}} /> </div> 
    )
}

function mapStateToProps(state) {
    return {
            auth: state.auth, 
            masterguests: state.masterguests,
            settings: state.settings,
            photos: state.photos,
            photo: state.photo
           }
  }

export default withRouter(connect(mapStateToProps,{getGuests,addGuest, removeGuest, getSettings, setSetting, getPhotos, addPhoto, removePhoto, setPhotoInfo})(Settings))