import React, {Component, useEffect} from 'react';
import { useState } from "react";
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GroupIcon from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import {connect} from 'react-redux';
import * as actions from '../actions'
import Header from './Header';
import Landing from './Landing';
import Footer from './Footer';
import TOU from './TOU';
import Cookies from './Cookies';
import Privacy from './Privacy';
import Signin from './SignIn';
import NotFound from './NotFound';
import Family from './Family';
import Calendar from './Calendar';
import Chores from './Chores';
import Groceries from './Groceries';
import Crypto from './Crypto';
import Sports from './Sports';
import Countdown from './Countdown';
import Settings from './Settings';
import Photos from './Photos';
import Payment from './Payment';
import { Link } from "react-router-dom";
import { rgbToHex } from '@mui/material';

const App = ({auth,fetchUser}) => {
    const [paid, setPaid] = useState(true)
    // componentDidMount() {
    //     this.props.fetchUser();
    // }

    useEffect( ()=>{
        fetchUser()
    }, [])

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const drawerWidth = 100
    
    const DrawerList = (
        <Box sx={{ width: drawerWidth }} role="presentation" onClick={toggleDrawer(false)}>
          { auth ? 
          <List >
              <ListItem disablePadding >
                <ListItemButton component={Link} to="/family">
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon >
                      <GroupIcon fontSize="large" style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Family"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding >
                <ListItemButton component={Link} to="/calendar">
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon >
                      <CalendarTodayIcon fontSize="large" style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Calendar"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding >
                <ListItemButton component={Link} to="/chores">
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon >
                      <FormatListBulletedIcon fontSize="large" style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Chores"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding >
                <ListItemButton component={Link} to="/groceries">
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon >
                      <LocalGroceryStoreIcon fontSize="large" style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Groceries"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding >
                <ListItemButton component={Link} to="/sports">
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon >
                      <ScoreboardIcon fontSize="large" style={{margin: '0 auto'}}/>
                    </ListItemIcon>
                    <ListItemText primary={"Sports"} style={{textAlign: 'center'}}/>
                  </div>
                </ListItemButton>
              </ListItem>
          </List>
          : null}
          <List style={auth ? { position: "absolute", bottom: '0' } : null}>
              <ListItem disablePadding >
                <ListItemButton component={auth ? "a" : Link} to={auth ? "/api/logout" : "/signin"}>
                  <div style={{margin: '0 auto'}}>
                    <ListItemIcon >
                      {auth ? <LogoutIcon fontSize="large" style={{margin: '0 auto'}}/> : <LoginIcon fontSize="large" style={{margin: '0 auto'}}/>}
                    </ListItemIcon>
                    {auth ? <ListItemText primary={"Logout"} style={{textAlign: 'center'}}/> : <ListItemText primary={"Login"} style={{textAlign: 'center'}}/> }
                  </div>
                </ListItemButton>
              </ListItem>
          </List>
        </Box>
      );


    // render() {
        return (
            <div className="" style={{ position: "relative" }}>
              <BrowserRouter style={{}}>
                <Box sx={{ display: 'flex' }}>
                    {/* <Drawer variant="permanent" sx={{width: drawerWidth,flexShrink: 0,'& .MuiDrawer-paper': {width: drawerWidth, boxSizing: 'border-box',}}} open={open} onClose={toggleDrawer(false)}>
                        {DrawerList}
                    </Drawer> */}
                    <Box component="main" id="main" style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}} sx={{flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                        
                            <div className="" style={{display: "flex",alignItems: "center",justifyContent: 'center', minHeight: "100vh",flexDirection: "column"}} >
                                <header id='header' style={{ width: "100%", top: "0px",zIndex:"1000", padding: window.innerWidth > 800 ? "0 10%" : "0 0"}}>
                                    <Header />
                                </header>
                                <main className="" style={{flex: "1 0 auto",width: window.innerWidth, padding: window.innerWidth > 800 ? "0 10%" : "0 0"}}>
                                    <Switch>
                                        <Route exact path="/" component={Landing} />
                                        {paid ? <Route exact path="/home" component={Calendar} /> : null}
                                        {paid ? <Route exact path='/calendar' component={Calendar} /> : null}
                                        <Route exact path='/signin' component={Signin} />
                                        {paid ? <Route exact path='/family' component={Family} /> : null}
                                        {paid ? <Route exact path='/lists' component={Groceries} /> : null}
                                        {paid ? <Route exact path='/countdown' component={Countdown} /> : null}
                                        {paid ? <Route exact path='/todos' component={Chores} /> : null}
                                        {paid ? <Route exact path='/sports' component={Sports} /> : null}
                                        <Route exact path='/payment' component={Payment} />
                                        {paid ? <Route exact path='/photos' component={Photos} /> : null}
                                        {paid ? <Route exact path='/crypto' component={Crypto} /> : null}
                                        {paid ? <Route exact path='/settings' component={Settings} /> : null}
                                        <Route exact path="/tou" component={TOU} />
                                        <Route exact path="/cookies" component={Cookies} />
                                        <Route exact path="/privacy" component={Privacy} />
                                        <Route exact component={NotFound} />
                                    </Switch>
                                </main>
                                <footer id='footer' style={{width: "100%"}}>
                                    <Footer style={{alignSelf: "flex-end"}}/>
                                </footer>
                            </div>
                        
                    </Box>
                </Box>
                </BrowserRouter>
            </div>
        )
    // }
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
         }
}

export default connect(mapStateToProps, actions)(App);