import React, {useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import { getFamily, getTodos, addTodo, removeTodo, toggleTodo, clearTodo, editTodo } from '../actions';
import { useState } from "react";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import Avatar from '@mui/material/Avatar';

const projectFont = '"Slabo 27px", serif'
// const projectFont = '"BioRhyme", serif'

const FormCheckBox = ({ item, index, status, memid, toggleTodo, removeTodo, editTodo }) => {
  const [checked, setChecked] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [editlabel, setEditlabel] = useState(false);
  const [label, setLabel] = useState('')
  const [scroll, setScroll] = useState(false);
  const [conwidth, setConwidth] = useState(0);
  const spanRef = useRef()

  useEffect(()=>{
    setTimeout(function (){
      setConfetti(false)
    }, 4000); 
    
  },[confetti])

  useEffect(()=>{
      setChecked(status)
      setLabel(item)
      const t = document.getElementById('tf')
      setConwidth(t.offsetWidth)
  },[])

  useEffect(()=>{
    if (spanRef.current) {
    if (spanRef.current.offsetWidth > conwidth) {
      setScroll(true)
    }
  }
  },[label])

  useEffect(()=>{
    setChecked(status)
    setLabel(item)
  },[item])

  const deleteTask = async (event, id, idx) => {
    await removeTodo({id, idx})
  }

  const toggleCheck = () => {
    const x = document.getElementsByClassName(`${memid}-chbx`)

    setChecked(!checked);

    let checker = arr => arr.every(Boolean);
    let alldone = []
    if ( x.length > 0) {
      setTimeout(function (){
        for (let step = 0; step < x.length; step++) {
          alldone.push(x[step].children[0].checked)
        }
        toggleTodo({id: memid, values:alldone})
        if (checker(alldone)) {
          setConfetti(true)
        }
      }, 5); 
    }
  }

  const labelTouched = (event) => {
    setEditlabel(true)
  }

  const labelChanged = (event) => {
    setLabel(event.target.value)
  }

  const doneEdit = async (event) => {
    if (label != item) {
      await editTodo({label: label, idx: index, id: memid})
    }
    setEditlabel(false)
  }

  const doneEdit2 = async (event) => {
    setEditlabel(false)
  }
  
  return (
    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      <Checkbox className={`${memid}-chbx ${memid}-${index}`} checked={checked} onClick={toggleCheck} inputProps={{className: `${memid}-chbx-id`}}/>
      <div style={{marginLeft: '7px', width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center'}} id='tf'>
        {/* <TextField onClick={(event)=>labelTouched(event)} onBlur={(event)=>doneEdit(event)} onChange={(event)=>labelChanged(event)} disabled={!editlabel} inputRef={(input) => editlabel === true && input?.focus()}  value={label} fullWidth sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, ".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true}} name='todoname' variant="standard" placeholder="Task" style={{marginTop: '7px', marginBottom: '7px',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}}/> */}
        {editlabel ? <TextField inputProps={{}}   onClick={(event)=>labelTouched(event)} onBlur={(event)=>doneEdit(event)} onChange={(event)=>labelChanged(event)} disabled={!editlabel} inputRef={(input) => editlabel === true && input?.focus()}  value={label} fullWidth sx={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#000000"}, ".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true}} name='todoname' variant="standard" placeholder="Task" style={{marginTop: '7px', marginBottom: '7px',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}}/>
        : <div style={{display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%'}}>
            <span className={scroll & !checked ? 'marquee cap' : 'cap'} style={{padding: scroll & !checked ? '0 30px' : '0 0', whiteSpace: 'nowrap',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}} ref={spanRef} onClick={(event)=>labelTouched(event)}>{label}</span>
            {scroll ? <span className={scroll & !checked ? 'marquee cap' : 'cap'} style={{padding: scroll & !checked ? '0 30px' : '0 0', whiteSpace: 'nowrap',textDecorationLine: checked ? "line-through" : "none", textDecorationThickness: '3px', opacity: checked ? 0.4 : 1}} onClick={(event)=>labelTouched(event)}>{label}</span> : null}
          </div>
        }
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {
          !editlabel 
            ? <Button variant="outlined" color='error' onClick={(event)=>deleteTask(event,memid, index)} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', border: 'none'}} ><ClearIcon sx={{ fontSize: 32 }}/></Button>
            : <Button variant="outlined" onClick={(event)=>doneEdit2(event)} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', border: 'none'}} ><CheckIcon sx={{ fontSize: 32, color: 'green' }}/></Button>
        }
      </div>
      {confetti ? <Fireworks autorun={{ speed: 3 }} /> : null}
    </div>
  )
}


const Chores = ({family,getFamily,auth,mastertodos,getTodos, addTodo, removeTodo, toggleTodo, clearTodo, editTodo}) => {
    const [fam, setFam] = useState([])
    const [todos, setTodos] = useState({})
    const [listheight, setListheight] = useState(0)

    useEffect(()=>{
      getFamily()
      getTodos()
      console.log(window.innerHeight)
      const x = 100/window.innerHeight
      console.log(x)
      const y = 85/x
      if (window.innerWidth > 800) {
        setListheight(y-210-30-8-60-40-30-10)
      } else {
        setListheight(y-125-30-8-60-40-30-10)
      }
    },[])

    useEffect(()=>{
      setTodos(mastertodos)
    }, [mastertodos])

    useEffect(()=>{
      setFam(family)
    }, [family])

    const addNewTask = async (event, id) => {
      event.preventDefault()
      const task = event.target[0].value
      event.target.reset();
      await addTodo({task,id})
      await getTodos()
    }

    const deleteTask = async (event, id, idx) => {
      await removeTodo({id, idx})
    }

    const clearAllTodos = async (event, id) => {
      await clearTodo({id})
      event.target.blur()
    }

    const resetAllTodos = async (event, id) => {
      const x = document.getElementsByClassName(`${id}-chbx`)
      if (x.length > 0) {
        const y = new Array(x.length).fill(false);
        await toggleTodo({id: id, values: y})
        await getTodos()
        for (let step = 0; step < x.length; step++) {
          if (x[step].children[0].checked === true) {
            x[step].children[0].click()
          }
        }
      }
      event.target.blur()
    }
    
    const todoArray = fam?.map((member,index) => {
      return (
        todos != null ? 
        <div key={member.id} style={{overflow: 'hidden', flex: window.innerWidth > 800 ? '0 0 300px' : '0 0 250px',display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', margin: '0 10px'}}>
          <div className='' style={{overflow: ''}}>
          <div style={{textAlign: 'center'}}>
            
            { member.photo 
                ? <Avatar alt={member.name} src={member.photo} className="imgav" style={{marginTop: '0px', marginLeft: 'auto', marginRight: 'auto', border: `3px solid ${member.color}`}} sx={{ bgcolor: `${member.usercolor}`, width: window.innerWidth > 800 ? 210 : 125, height: window.innerWidth > 800 ? 210 : 125  }}></Avatar>
                : <Avatar className="imgav" style={{marginTop: '0px', marginLeft: 'auto', marginRight: 'auto'}} sx={{ bgcolor: `${member.color}`, width: window.innerWidth > 800 ? 210 : 125, height: window.innerWidth > 800 ? 210 : 125 , fontSize: window.innerWidth > 800 ? 72 : 50 }}>{member.initials}</Avatar>
            }
            <span style={{fontFamily: projectFont, fontSize: '30px'}}>{member.name}</span>
          </div>
          <div style={{height: listheight, overflowY: 'auto'}}>
          {todos[member.id]?.task.map((item,index) => {
            return (
              <div key={index} style={{height: '60px',margin: '4px 7px',borderColor: 'rgb(0,0,0)', borderRadius: '10px', boxShadow: 'inset 0.1em 0.1em 0.1em 0 rgba(0,0,0,0.5), inset -0.1em -0.1em 0.1em 0 rgba(0,0,0,0.5)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <FormCheckBox key={index} status={todos[member.id].status[index]} item={todos[member.id].task[index]} memid={member.id} index={index} toggleTodo={toggleTodo} removeTodo={removeTodo} editTodo={editTodo}/>
                {/* <Button variant="outlined" color='error' onClick={(event)=>deleteTask(event,member.id, index)} sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', border: 'none'}} ><ClearIcon sx={{ fontSize: 32 }}/></Button> */}
              </div>
            )
          }) 
          }
          
          
          <div style={{height: '60px',margin: '4px 7px',borderColor: 'rgb(242,242,242)', borderRadius: '10px', boxShadow: 'inset 0.1em 0.1em 0.1em 0 rgba(0,0,0,0.5), inset -0.1em -0.1em 0.1em 0 rgba(0,0,0,0.5)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <form noValidate autoComplete="off" onSubmit={(event) => addNewTask(event,member.id)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%'}} >
              <div style={{marginLeft: '7px', width: '100%'}} >
                <TextField fullWidth sx={{".MuiInputBase-input": {margin: '0 !important',boxShadow: '0 0 0 0 !important',borderBottom: '0 !important'}}}  InputProps={{ disableUnderline: true }} name='todoname' variant="standard" placeholder="Enter Task ..." style={{marginTop: '7px', marginBottom: '7px'}}/>
              </div>
              <Button variant="outlined" type="submit" sx={{fontSize: '30px', padding: 0, height: '40px', width: '40px', minWidth: '40px', marginRight: '7px', color: 'rgb(25,118,210)', border: 'none',outline: 'none'}}><AddIcon  sx={{ fontSize: 32 }}/></Button>
            </form>
          </div>
          </div>
          </div>
          <div style={{height: '40px',minHeight: '40px',marginLeft: '7px', marginRight: '7px', marginTop: '30px', marginBottom: '10px',borderColor: 'rgb(242,242,242)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {/* <Button variant="outlined" style={{margin: '0 auto', width: '100%', height: '100%', borderRadius:'10px'}} onClick={(event)=>clearAllTodos(event,member.id)} color='error' sx={{fontSize: '18px', padding: 0, marginRight: '7px', border: 'none', outline: 'none', textTransform: 'none'}}>Clear All</Button> */}
              <Button variant="outlined" style={{margin: '0 auto', width: '40%', height: '85%', borderRadius:'10px'}} onClick={(event)=>resetAllTodos(event,member.id)} color='error' sx={{fontSize: '18px', padding: 0, marginRight: '7px', textTransform: 'none'}}>Reset All</Button>
              <Button variant="outlined" style={{margin: '0 auto', width: '40%', height: '85%', borderRadius:'10px'}} onClick={(event)=>clearAllTodos(event,member.id)} color='error' sx={{fontSize: '18px', padding: 0, marginRight: '7px', textTransform: 'none'}}>Delete All</Button>
              
          </div>
        </div>
      : null)
    })
    
    return(
      <div style={{paddingTop: '40px', paddingBottom: '20px', height: '85vh', width: '100%', display: 'flex', flexDirection: 'row', overflowX: 'scroll'}}>
          {todoArray}
      </div>
    )
}

function mapStateToProps(state) {
  return {
          auth: state.auth, 
          family: state.family, 
          mastertodos: state.mastertodos,
         }
}

export default withRouter(connect(mapStateToProps,{getFamily,getTodos, addTodo, removeTodo, toggleTodo, clearTodo, editTodo})(Chores))