import {ADD_FAMILY,GET_FAMILY,REMOVE_FAMILY,EDIT_FAMILY} from '../actions/types';

export default function(state = null,action) {
    switch (action.type) {
        case ADD_FAMILY:
            return action.payload || false;
        case GET_FAMILY:
            return action.payload || false;
        case REMOVE_FAMILY:
            return action.payload || false;
        case EDIT_FAMILY:
            return action.payload || false;
        default:
            return state;
    }
}